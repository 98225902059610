import CountUp from "react-countup";
import { Link } from "react-router-dom";
import {
  CountValue,
  Percent,
  Title,
  Wrapper,
  Icon,
  PaymentCount,
} from "./card-animate.e";
import { FlexCommon } from "../../common/global/global";

function CardAnimate({
  title,
  percent,
  count,
  icon,
  iconBg,
  iconColor,
  prefix,
  count2,
}) {
  return (
    <Wrapper>
      <FlexCommon>
        <Title style={count2 !== undefined ? { marginBottom: "22px" } : {}}>
          {title}
        </Title>
        {percent && (
          <Percent
            className={
              percent > 0
                ? "green"
                : percent > -10 && percent < 0
                ? "yellow"
                : percent === 0
                ? "dark"
                : "red"
            }
          >
            {percent > 0 ? (
              <i className="fa-regular fa-arrow-up-right" />
            ) : percent === 0 ? (
              <i className="fa-regular fa-plus" />
            ) : (
              <i className="fa-regular fa-arrow-down-right" />
            )}
            {percent}%
          </Percent>
        )}
      </FlexCommon>
      {count2 === undefined && (
        <>
          <CountValue>
            <CountUp
              start={count - count * 0.5}
              separator=","
              end={count}
              duration="3"
              suffix={prefix ? prefix : ""}
            />
          </CountValue>
          <Link to="/orders">View all orders</Link>
        </>
      )}
      {count2 !== undefined && (
        <>
          <PaymentCount>
            <CountUp
              start={count - count * 0.5}
              separator=","
              end={count}
              duration="1"
              prefix="Cash: "
            />
          </PaymentCount>
          <PaymentCount>
            <CountUp
              start={count2 - count2 * 0.5}
              separator=","
              end={count2}
              duration="1"
              prefix="Pys: "
            />
          </PaymentCount>
        </>
      )}
      <Icon style={{ backgroundColor: iconBg, color: iconColor }}>{icon}</Icon>
    </Wrapper>
  );
}

export default CardAnimate;
