import { useState } from "react";
import Button from "../../components/common/button/button";
import Modal from "../../components/common/modal/modal";
import Search from "../../components/common/search/search";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { SearchResults } from "./order-edit.e";

function AddProduct({ order, setOrder }) {
  const [modal, setModal] = useState();
  const [search, setSearch] = useState();
  const { data: items } = useAxiosFetch(
    "https://beeto.in1.uz/api/item/moderator/get-pagin",
    {
      limit: 20,
      page: 1,
      search: `${search}`,
    }
  );

  return (
    <>
      <div className="right">
        <Button
          onClick={() => setModal(true)}
          text="Add Product"
          color="primary"
        />
        {modal && (
          <Modal
            width={window.innerWidth < 800 ? "320px" : "550px"}
            setOpen={() => setModal(false)}
            open={modal}
          >
            <Search onChange={setSearch} value={search} />
            <SearchResults>
              {items?.data?.map((item, i) => {
                return (
                  <p
                    onClick={() => {
                      let oldStates = { ...order };
                      oldStates?.items?.unshift({
                        count: 1,
                        item_id: item._id,
                        name: item.name,
                        price: item?.prices[0]?.price
                          ? item.prices[0].price
                          : 0,
                        representation: item.representation,
                        size: item.size,
                        size_type: item.size_type,
                        sku: item.sku,
                        sold_by: item.sold_by,
                      });
                      setOrder(oldStates);
                      setModal(false);
                    }}
                    className="search-result"
                    key={i}
                  >
                    {item.name}
                  </p>
                );
              })}
            </SearchResults>
          </Modal>
        )}
      </div>
    </>
  );
}

export default AddProduct;
