import { useState } from "react";
import Button from "../../common/button/button";
import Modal from "../../common/modal/modal";
import { Buttons } from "../../categories/categories.e";

function Logout() {
  const [modal, setModal] = useState(false);
  return (
    <>
      <Button
        // icon
        ghost="secondary"
        icon="header"
        text={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-log-out"
          >
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
            <polyline points="16 17 21 12 16 7" />
            <line x1="21" x2="9" y1="12" y2="12" />
          </svg>
        }
        onClick={() => setModal(true)}
      />
      {modal && (
        <Modal width="400px" setOpen={() => setModal(false)} open={modal}>
          <h5>Are you sure ?</h5>
          <p className="text">
            Do you want to leave the <br /> website?
          </p>
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Close"
              color="light"
            />
            <Button
              onClick={() => {
                localStorage.removeItem("userData");
                window.location.reload(false);
              }}
              text="Log out"
              color="danger"
            />
          </Buttons>
        </Modal>
      )}
    </>
  );
}

export default Logout;
