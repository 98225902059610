import { useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Buttons, Wrapper } from "./categories.e";
import CategoryItem from "./category-item/category-item";
import AddCategory from "./add-category/add-category";
import { AddButton } from "./add-category/add-category.e";
import Button from "../common/button/button";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import useTags from "../../hooks/useTags";
import Select from "../common/select/select";
import { onUpdatePosition } from "../../services";

function CategoriesContent({ categories, loaded }) {
  const [positions, setPositions] = useState();
  const [category, setCategory] = useState(categories);
  const [changePosition, setChangePosition] = useState(false);
  const { tagDropdown } = useTags();
  const navigate = useNavigate();
  const { select: types } = useAxiosFetch(
    "https://beeto.in1.uz/api/category/moderator/get",
    undefined,
    true,
    { label: "top", value: "top" }
  );

  // Format categories for positions like {_id: '6151ac', draggable_position: 0}
  useEffect(() => {
    let temporal = [];
    let count = 0;
    category?.map((category) => {
      count = ++count;
      temporal.push({
        _id: category._id,
        draggable_position: count,
      });
      // category.children.map((child) => {
      //   count = ++count;
      //   return temporal.push({
      //     _id: child._id,
      //     draggable_position: count,
      //   });
      // });
    });
    setPositions(temporal);
  }, [loaded, category]);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  const updatePosition = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/category/draggable/update`,
    //     {
    //       categories: positions,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      onUpdatePosition({ categories: positions,})
      .then(() => {
        navigate(0);
      });
  };

  return (
    !loaded && (
      <Wrapper>
        <AddCategory>
          <AddButton>
            {!changePosition && (
              <Button
                onClick={() => setChangePosition("Top categories")}
                outline={!changePosition}
                color="secondary"
                text={"Change position"}
              />
            )}
            {changePosition && (
              <Select
                id="size_type"
                onChange={(e) => setChangePosition(e.value)}
                defaultOption={changePosition}
                options={["Top categories", "Sub categories"]}
              />
            )}
            {changePosition && (
              <Buttons style={{ padding: 0 }}>
                <Button
                  onClick={() => {
                    updatePosition();
                  }}
                  color="primary"
                  text="Save"
                />
              </Buttons>
            )}
          </AddButton>
        </AddCategory>
        {category && changePosition === "Top categories" && (
          <List
            values={category ? category : ""}
            onChange={({ oldIndex, newIndex }) => {
              setCategory(arrayMove(category, oldIndex, newIndex));
              category[oldIndex].draggable_position = newIndex;
              category[newIndex].draggable_position = oldIndex;
            }}
            renderList={({ children, props }) => <ul {...props}>{children}</ul>}
            renderItem={({ value, props }) => (
              <div key={value._id} {...props}>
                <CategoryItem
                  types={types}
                  tagDropdown={tagDropdown}
                  category={value}
                />
              </div>
            )}
          />
        )}
        {changePosition !== "Top categories" &&
          categories?.map((category, i) => {
            return (
              <CategoryItem
                position={changePosition}
                types={types}
                tagDropdown={tagDropdown}
                category={category}
                key={category._id}
                index={i}
              />
            );
          })}
      </Wrapper>
    )
  );
}

export default CategoriesContent;
