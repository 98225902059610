import { useEffect, useState, useContext } from "react";
import { Collapser, Trigger, Panel } from "react-collapser";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { MenuSizeContext } from "../../context/menuSize";
import Icon from "../common/Icon/Icon";
import Reports from "../../assets/images/reports (1).svg"
import Bookmark from "../../assets/images/bookmark.svg"
import Car from "../../assets/images/car-side.svg"
import User from "../../assets/images/user.svg"
import ShopCart from "../../assets/images/cart.svg"
import Down from "../../assets/images/chevron-down.svg"
import CirclePlus from "../../assets/images/plus-circle.svg"
import Folder from "../../assets/images/folder-plus.svg"
import Bag from "../../assets/images/shopping-bag.svg"
import Hash from "../../assets/images/hashtag.svg"
import Left from "../../assets/images/align-left.svg"
import Slider from "../../assets/images/sliders.svg"
import Gears from "../../assets/images/gear.svg"
import Layer from "../../assets/images/layer-plus.svg"
import News from "../../assets/images/bullhorn.svg"
import Location from "../../assets/images/alternate-map-marker.svg"
import Percent from "../../assets/images/percentage.svg"
import Ad from "../../assets/images/ad.svg"
import Admins from "../../assets/images/headset-help.svg"

import {
  ForDesktop,
  LinkItem,
  Links,
  Logo,
  Responsive,
  Wrapper,
  Closer,
} from "./menu.e";
import LogoIcon from "../../assets/images/logo-icon.svg";

function Menu() {
  const { size, toggleMenu } = useContext(MenuSizeContext);
  const [newOrder, setNewOrder] = useState(0);

  useEffect(() => {
    axios
      .post(
        "https://beeto.in1.uz/api/moderator-order/get-pagin",
        {
          page: 1,
          state: "new-order",
          limit: 10,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
          },
        }
      )
      .then((response) => {
        setNewOrder(response.data.data?.new_orders);
      }).catch (()=>{
        localStorage.setItem("userData", "");

      })
      ;
  }, []);

  return (
    <>
      <ForDesktop>
        <Wrapper className={size ? "small" : ""}>
          <Logo
            onClick={() => window.location.reload()}
            className={size ? "small" : ""}
          >
            <img src={LogoIcon} alt="Beeto" /> <span>Beeto</span>
          </Logo>
          <Links className={size ? "small" : ""}>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <LinkItem>
              <Icon file={Reports}   title="speed"/>

                <span>Reports</span>
              </LinkItem>
            </NavLink>
            <NavLink
              to="/orders"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <LinkItem>
              <Icon file={Bookmark}   title="speed"/>

                {/* <i className="fa-regular fa-bookmark" /> */}
                <span>Orders</span>
                {newOrder > 0 && <b>{newOrder}</b>}
              </LinkItem>
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/drivers"
            >
              <LinkItem>
              <Icon file={Car}   title="car"/>

                {/* <i className="fa-regular fa-car-side" /> */}
                <span>Drivers</span>
              </LinkItem>
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/clients"
            >
              <LinkItem>
            
              <Icon file={User}   title="user"/>

                {/* <i className="fa-regular fa-user" /> */}
                <span>Clients</span>
              </LinkItem>
            </NavLink>
            <Collapser className="accordion">
              <Trigger>
                <LinkItem>
        
              <Icon  file={ShopCart}   title="cart"/>

                  {/* <i className="fa-regular fa-cart-shopping" /> */}
                  <span>Products</span>

              <Icon className="collops" file={Down}   title="user"/>

                  {/* <i className="fa-solid fa-chevron-down" /> */}
                </LinkItem>
              </Trigger>
              <Panel>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/new-products"
                >
                  <LinkItem>
              <Icon file={CirclePlus}   title="circle"/>

                    {/* <i className="fa-regular fa-circle-plus" /> */}
                    <span>New Products</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/new-category"
                >
                  <LinkItem>
              <Icon file={Folder}   title="folder"/>

                    {/* <i className="fa-regular fa-folder-plus" /> */}
                    <span>New Category</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/products/all"
                >
                  <LinkItem>
              <Icon file={Bag}   title="bag"/>

                    {/* <i className="fa-regular fa-bag-shopping" /> */}
                    <span>Products</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/tags"
                >
                  <LinkItem>
              <Icon file={Hash}   title="hash"/>

                    {/* <i className="fa-regular fa-hashtag" />  */}
                    
                    <span>Tags</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/categories"
                >
                  <LinkItem>
              <Icon file={Left}   title="align"/>

                    {/* <i className="fa-solid fa-align-left" />{" "} */}
                    <span>Categories</span>
                  </LinkItem>
                </NavLink>
              </Panel>
            </Collapser>
            <Collapser className="accordion">
              <Trigger>
                <LinkItem>
              <Icon file={Slider}   title="slider"/>

                  {/* <i className="fa-regular fa-sliders-up" />{" "} */}
                  <span>Settings</span>{" "}
              <Icon className="collops" file={Down}   title="down"/>

                  {/* <i className="fa-solid fa-chevron-down" /> */}
                </LinkItem>
              </Trigger>
              <Panel>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/settings"
                >
                  <LinkItem>
              <Icon file={Gears}   title="gear"/>

                    {/* <i className="fa-regular fa-gears" /> */}
                    <span>General</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/banners"
                >
                  <LinkItem>
              <Icon file={Layer}   title="layer"/>

                    {/* <i className="fa-regular fa-layer-plus" /> */}
                    <span>Banners</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/news"
                >
                  <LinkItem>
              <Icon file={News}   title="news"/>

                    {/* <i className="fa-regular fa-bullhorn" /> */}
                    <span>News</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/regions"
                >
                  <LinkItem>
              <Icon file={Location}   title="location"/>
                  
                    {/* <i className="fa-regular fa-location-dot" />{" "} */}
                    <span>Regions</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/promo-code"
                >
                  <LinkItem>
              <Icon file={Percent}   title="percent"/>

                    {/* <i className="fa-solid fa-percent" /> */}
                    <span>Promo Code</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/ads"
                >
                  <LinkItem>
              <Icon file={Ad}   title="percent"/>

                    {/* <i className="fa-regular fa-rectangle-ad" /> */}
                    <span>Advertisements</span>
                  </LinkItem>
                </NavLink>
              </Panel>
            </Collapser>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/moderators"
            >
              <LinkItem>
              <Icon file={Admins}   title="percent"/>

                {/* <i className="fa-solid fa-headset"></i> */}
                <span>Moderators</span>
              </LinkItem>
            </NavLink>
          </Links>
        </Wrapper>
      </ForDesktop>
      <Responsive style={size ? { display: "block" } : { display: "none" }}>
        <Wrapper className="responsive">
          <Closer onClick={() => toggleMenu(!size)}>
            <i className="fa-solid fa-xmark" />
          </Closer>
          <Logo
            onClick={() => {
              window.location.reload();
              toggleMenu(!size);
            }}
          >
            <img src={LogoIcon} alt="Beeto" /> <span>Beeto</span>
          </Logo>
          <Links>
            <NavLink
              onClick={() => toggleMenu(!size)}
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <LinkItem>
                <i className="fa-regular fa-gauge-high" /> <span>Reports</span>
              </LinkItem>
            </NavLink>
            <NavLink
              onClick={() => toggleMenu(!size)}
              to="/orders"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <LinkItem>
                <i className="fa-regular fa-bookmark" />
                <span>Orders</span>
                {newOrder > 0 && <b>{newOrder}</b>}
              </LinkItem>
            </NavLink>
            <NavLink
              onClick={() => toggleMenu(!size)}
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/drivers"
            >
              <LinkItem>
                <i className="fa-regular fa-car-side" />
                <span>Drivers</span>
              </LinkItem>
            </NavLink>
            <NavLink
              onClick={() => toggleMenu(!size)}
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/clients"
            >
              <LinkItem>
                <i className="fa-regular fa-user" />
                <span>Clients</span>
              </LinkItem>
            </NavLink>
            <Collapser className="accordion">
              <Trigger>
                <LinkItem>
                  <i className="fa-regular fa-cart-shopping" />
                  <span>Products</span>
                  <i className="fa-solid fa-chevron-down" />
                </LinkItem>
              </Trigger>
              <Panel>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/new-products"
                >
                  <LinkItem>
                    <i className="fa-regular fa-circle-plus" />
                    <span>New Products</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/new-category"
                >
                  <LinkItem>
                    <i className="fa-regular fa-folder-plus" />
                    <span>New Category</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/products/all"
                >
                  <LinkItem>
                    <i className="fa-regular fa-bag-shopping" />
                    <span>Products</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/tags"
                >
                  <LinkItem>
                    <i className="fa-regular fa-hashtag" /> <span>Tags</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/categories"
                >
                  <LinkItem>
                    <i className="fa-solid fa-align-left" />{" "}
                    <span>Categories</span>
                  </LinkItem>
                </NavLink>
              </Panel>
            </Collapser>
            <Collapser className="accordion">
              <Trigger>
                <LinkItem>
                  <i className="fa-regular fa-sliders-up" />{" "}
                  <span>Settings</span>{" "}
                  <i className="fa-solid fa-chevron-down" />
                </LinkItem>
              </Trigger>
              <Panel>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/settings"
                >
                  <LinkItem>
                    <i className="fa-regular fa-gears" />
                    <span>General</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/banners"
                >
                  <LinkItem>
                    <i className="fa-regular fa-layer-plus" />
                    <span>Banners</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/news"
                >
                  <LinkItem>
                    <i className="fa-regular fa-bullhorn" />
                    <span>News</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  onClick={() => toggleMenu(!size)}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/regions"
                >
                  <LinkItem>
                    <i className="fa-regular fa-location-dot" />{" "}
                    <span>Regions</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/promo-code"
                >
                  <LinkItem>
                    <i className="fa-solid fa-percent" />
                    <span>Promo Code</span>
                  </LinkItem>
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/ads"
                >
                  <LinkItem>
                    <i className="fa-regular fa-rectangle-ad" />
                    <span>Advertisements</span>
                  </LinkItem>
                </NavLink>
              </Panel>
            </Collapser>

            <NavLink
              onClick={() => toggleMenu(!size)}
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/moderators"
            >
              <LinkItem>
                <i className="fa-solid fa-headset" />
                <span>Moderators</span>
              </LinkItem>
            </NavLink>
          </Links>
        </Wrapper>
      </Responsive>
    </>
  );
}

export default Menu;
