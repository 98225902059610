import styled from "styled-components";

export const Wrapper = styled.div``;

export const ModeratorCard = styled.div`
  padding: 1.2rem !important;
  background-color: var(--card-bg);
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--text);
  i {
    color: var(--indigo);
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;
