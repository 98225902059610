import styled from "styled-components";

export const Wrapper = styled.div`
  transition: 0.3s all ease-in-out;
`;

export const Card = styled.div`
  padding: 1rem;
  background-color: var(--card-bg);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  margin-top: 22px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  p {
    font-size: 16px;
    color: var(--heading);
    padding-bottom: 8px;
    border-bottom: 1px solid #e9ebec;
    margin-bottom: 16px;
    font-weight: 700;
  }
`;

export const Greeting = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 17px;
    margin-bottom: 0.25rem;
    color: var(--heading);
    font-weight: 600;
  }
  span {
    color: #878a99;
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 40% 50%;
    p {
      font-size: 15px;
      line-height: 1.3;
    }
    span {
      margin-top: 5px;
      font-size: 10px;
    }
  }
`;

export const Relative = styled.div`
  position: relative;
  .date {
    position: absolute;
    top: -30px;
    right: 0;
    z-index: 999;
  }
`;
