import styled from "styled-components";

export const Wrapper = styled.div`
  .right {
    margin: 30px auto;
    text-align: right;
  }
`;

export const Products = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const BasketItem = styled.div`
  border-radius: 5px;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
`;

export const CardBody = styled.div`
  padding: 1rem 1rem;
  display: grid;
  grid-template-columns: 90px 4fr auto;
  grid-gap: 20px;
  color: var(--text);
  span {
    color: #878a99;
    font-size: 0.8125rem;
  }
  .img {
    padding: 1px;
    border-radius: 8px;
    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--border-color);
    }
  }
  .info {
    p {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      margin-bottom: 4px;
      height: 42px;
    }
    span {
      display: block;
      margin-bottom: 10px;
    }
  }
  .price {
    p {
      font-weight: 600;
    }
  }
`;

export const CardFooter = styled.div`
  border-top: 1px solid var(--border-color);
  padding: 12px;
  justify-content: space-between;
  color: var(--text);
  &,
  p {
    display: flex;
    align-items: center;
  }
  span,
  i {
    margin-right: 8px;
    color: #878a99;
  }
  button {
    color: #878a99;
    background-color: transparent;
    cursor: pointer;
    font-size: 12px;
    padding-left: 4px;
  }
`;

export const CountButtons = styled.div`
  border: 1px solid var(--border-color);
  display: inline-flex;
  overflow: visible;
  height: 35px;
  border-radius: 0.25rem;
  padding: 4px;
  button {
    width: 1.4em;
    font-weight: 300;
    height: 100%;
    line-height: 0.1em;
    font-size: 1.4em;
    background: var(--body);
    color: var(--text);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    i {
      font-size: 14px;
    }
  }
  span {
    width: 4em;
    height: 100%;
    text-align: center;
    border: 0;
    transform: translateY(3px);
    background: 0 0;
    border-radius: 0.25rem;
    font-weight: 500;
  }
`;

export const SearchResults = styled.div`
  height: 200px;
  overflow-y: auto;
  p {
    padding: 2px;
    margin: 8px !important;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      background-color: var(--light);
    }
  }
`;

export const Cards = styled.div`
  margin-top: 24px;
  border-radius: 5px;
  width: 100.5%;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  padding: 15px 20px;
  .used {
    color: var(--yellow);
    font-weight: 500;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
  }
  label {
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    &.active * {
      color: var(--blue) !important;
    }
  }
  input {
    margin-right: 12px;
    transform: translateY(1px);
  }
  span {
    color: var(--green);
    font-size: 12px;
    margin-left: 8px;
    transform: translateY(-5px);
  }
`;
