import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper } from "../../components/common/global/global";
import CategoriesContent from "../../components/categories/categories";
import useAxiosFetch from "../../hooks/useAxiosFetch";

function Categories() {
  const { data: categories, loaded } = useAxiosFetch(
    "https://beeto.in1.uz/api/category/moderator/get"
  );

  return (
    <>
      <Pagename text={"Categories"} />
      <PageWrapper>
        <CategoriesContent categories={categories} loaded={loaded} />
      </PageWrapper>
    </>
  );
}

export default Categories;
