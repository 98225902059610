import { request } from "../api/axios";

export const getClients = (data) => {
    return request({ method: "post", url: "/client/get-pagin", data });
  };

  export const onUpdateClient=(data)=>{
    return request({method:"post", url:"/client-update", data})
  }
  export const uploadImg=(data)=>{
    return request({method:"post", url:"/upload-file", data})
  }