import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "../../../components/common/modal/modal";
import Button from "../../../components/common/button/button";
import UploadImg from "../../../components/common/upload-img/upload-img";
import { Buttons } from "../../../components/categories/categories.e";
import { Wrapper } from "./banner-card.e";
import { deleteBanner, updateBanner, uploadImg } from "../../../services";

function BannerCard({ banner }) {
  const [modal, setModal] = useState(false);
  const fields = useFormik({
    initialValues: {
      _id: banner._id,
      image: banner.image,
    },
  });
  const navigate = useNavigate();

  // Update banner
  const onUpdate = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/banner/update`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      updateBanner( { ...fields.values })
      
      .then((response) => {
        navigate(0);
      })
      .catch((error) => {
        localStorage.setItem("userData", "");
        navigate(0);
      });
  };

  // Update banner
  const onDeleteBanner = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/banner/delete`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    deleteBanner({ ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((error) => {
        localStorage.setItem("userData", "");
        navigate(0);
      });
  };

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    // axios
    //   .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <Wrapper>
      <img onClick={() => setModal(true)} src={fields.values.image} alt="" />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "340px" : "400px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <UploadImg
            width="100%"
            height="180px"
            url={fields.values.image}
            onChange={(e) => {
              uploadAvatar(e);
            }}
          />
          <Buttons>
            <Button onClick={onDeleteBanner} text="Delete" color="danger" />
            <Button
              onClick={() => setModal(false)}
              text="Close"
              color="light"
            />
            <Button onClick={onUpdate} text="Update" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Wrapper>
  );
}

export default BannerCard;
