import { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Button from "../../../components/common/button/button";
import Modal from "../../../components/common/modal/modal";
import CustomDropdown from "../../../components/common/dropdown/dropdown";
import { Buttons } from "../../../components/categories/categories.e";
import { EditItem, Label } from "../../item-edit/item-edit.e";
import { Navigate } from "react-router-dom";

function AddTag() {
  const [modal, setModal] = useState(false);

  const fields = useFormik({
    initialValues: {
      name: "",
    },
  });

  const createCategory = () => {
    axios
      .post(
        "https://beeto.in1.uz/api/tag/create",
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setModal(false);
        window.location.reload(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  return (
    <>
      <Button
        onClick={() => setModal(true)}
        text="Create tag"
        color="primary"
      />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "320px" : "550px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <EditItem>
            <Label>Tag Name</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="Enter category name"
              {...fields.getFieldProps("name")}
            />
          </EditItem>
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Cancel"
              color="light"
            />
            <Button onClick={createCategory} text="Create" color="primary" />
          </Buttons>
        </Modal>
      )}
    </>
  );
}

export default AddTag;
