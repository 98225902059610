import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useCategories from "../../../hooks/useCategory";
import useTags from "../../../hooks/useTags";
import Button from "../../common/button/button";
import Modal from "../../common/modal/modal";
import CustomDropdown from "../../common/dropdown/dropdown";
import UploadImg from "../../common/upload-img/upload-img";
import MultiSelect from "../../common/multi-select/multi-select";
import { Wrapper, AddButton } from "./add-category.e";
import { Buttons, Img } from "../categories.e";
import { EditItem, Label } from "../../../pages/item-edit/item-edit.e";

function AddCategory({ children }) {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const { types } = useCategories();
  const { tagDropdown } = useTags();
  const navigate = useNavigate();

  useEffect(() => {
    const formatted = [];
    selected.map((i) => {
      return formatted.push({ tag_id: i.value });
    });
    fields.setFieldValue("tags", formatted);
  }, [selected]);

  const fields = useFormik({
    initialValues: {
      name: "",
      icon: "",
      tags: [],
      type: "top",
      show_on_bot: true,
      image: "",
      image_size: "1x1",
    },
  });

  useEffect(() => {
    if (fields.values.type === "top") {
      fields.setFieldValue("image_size", "1x1");
      fields.setFieldValue("image", "");
      fields.setFieldValue("tags", "");
    }
  }, [fields.values.type]);

  const createCategory = () => {
    axios
      .post(
        "https://beeto.in1.uz/api/category/create",
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //   localStorage.setItem("userData", "");
          //   navigate(0);
        }
      });
  };

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    axios
      .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <Wrapper>
      <AddButton>
        <Button
          onClick={() => setModal(true)}
          outline
          color="primary"
          text="Add Category"
        />
      </AddButton>
      {children}
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "350px" : "550px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <Img>
            <UploadImg
              width="36%"
              height="100px"
              url={fields.values.image}
              onChange={(e) => {
                uploadAvatar(e);
              }}
            />
          </Img>
          <EditItem>
            <Label>Category Name</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="Enter category name"
              {...fields.getFieldProps("name")}
            />
          </EditItem>
          <EditItem>
            <Label>Category Type</Label>
            <CustomDropdown
              options={types}
              defaultOption={{
                label: fields.values.type,
                value: fields.values.type,
              }}
              onChange={(e) => fields.setFieldValue("type", e.value)}
            />
          </EditItem>
          {fields.values.type === "top" && (
            <EditItem>
              <Label>Icon</Label>
              <input
                className="form-control"
                placeholder="Enter font awesome class"
                autoComplete="off"
                type="text"
                {...fields.getFieldProps("icon")}
              />
            </EditItem>
          )}
          <EditItem>
            <Label>Tags</Label>
            <MultiSelect
              options={tagDropdown}
              selected={selected}
              setSelected={setSelected}
            />
          </EditItem>
          {fields.values.type !== "top" && (
            <EditItem>
              <Label>Image size</Label>
              <CustomDropdown
                options={[
                  { label: "1x1", value: "1x1" },
                  { label: "2x1", value: "2x1" },
                  { label: "3x1", value: "3x1" },
                ]}
                defaultOption={{
                  label: fields.values.image_size,
                  value: fields.values.image_size,
                }}
                onChange={(e) => {
                  fields.setFieldValue("image_size", e.value);
                }}
              />
            </EditItem>
          )}
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Cancel"
              color="light"
            />
            <Button onClick={createCategory} text="Create" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Wrapper>
  );
}

export default AddCategory;
