import Pagename from "../../components/common/pagename/pagename";
import { Grid4, PageWrapper } from "../../components/common/global/global";
import { Wrapper } from "./regions.e";
import { useEffect, useState } from "react";
import RegionContent from "./region-card/region-card";
import CreateRegion from "./create-region/create-region";
import { getRegions } from "../../services";

function Regions() {
  const [region, setRegion] = useState();

  // get regions
  useEffect(() => {
    getRegions().then((res) => {
      setRegion(res.data);
    });
  }, []);
  

  return (
    <>
      <Pagename text={"Regions"} />
      <PageWrapper>
        <Wrapper>
          <CreateRegion />
          <Grid4>
            {region?.map((r, i) => {
              return <RegionContent key={i} region={r} />;
            })}
          </Grid4>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default Regions;
