import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  width: 70%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Grid3 = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

export const TextRight = styled.div`
  text-align: right;
  padding: 15px 0;
`;
