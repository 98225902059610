import { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/common/modal/modal";
import Button from "../../../components/common/button/button";
import CustomDropdown from "../../../components/common/dropdown/dropdown";
import { EditItem, Label } from "../../item-edit/item-edit.e";
import { Wrapper } from "./create-driver.e";
import { Buttons } from "../../../components/categories/categories.e";
import UploadImg from "../../../components/common/upload-img/upload-img";
import MultiSelect from "../../../components/common/multi-select/multi-select";
import useRegions from "../../../hooks/useRegions";
import { Img } from "../../clients/clients.e";

function CreateDriver() {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const { regionsDropdown } = useRegions();

  useEffect(() => {
    const formatted = [];
    selected.map((i) => {
      return formatted.push(i.value);
    });
    fields.setFieldValue("regions", formatted);
  }, [selected]);

  const fields = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      status: "active",
      image: "",
    },
  });

  const createDriver = () => {
    axios
      .post(
        "https://beeto.in1.uz/api/driver/create",
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      });
  };

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    axios
      .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <Wrapper>
      <Button
        onClick={() => setModal(true)}
        outline
        color="primary"
        text="Add Driver"
      />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "320px" : "550px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <Img>
            <UploadImg
              width="150px"
              height="150px"
              url={fields.values.image}
              onChange={(e) => {
                uploadAvatar(e);
              }}
            />
          </Img>
          <EditItem>
            <Label>First Name</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="Enter first name"
              {...fields.getFieldProps("first_name")}
            />
          </EditItem>
          <EditItem>
            <Label>Last Name</Label>
            <input
              className="form-control"
              placeholder="Enter last name"
              autoComplete="off"
              type="text"
              {...fields.getFieldProps("last_name")}
            />
          </EditItem>
          <EditItem>
            <Label>Phone Number</Label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter phone number"
              autoComplete="off"
              {...fields.getFieldProps("phone_number")}
            />
          </EditItem>
          <EditItem>
            <Label>Regions</Label>
            <MultiSelect
              options={regionsDropdown && regionsDropdown}
              setSelected={setSelected}
            />
          </EditItem>
          <EditItem>
            <Label>Status</Label>
            <CustomDropdown
              options={[
                { label: "active", value: "active" },
                { label: "block", value: "block" },
              ]}
              defaultOption={{
                label: fields.values.status,
                value: fields.values.status,
              }}
              onChange={(e) => fields.setFieldValue("status", e.value)}
            />
          </EditItem>
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Cancel"
              color="light"
            />
            <Button onClick={createDriver} text="Create" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Wrapper>
  );
}

export default CreateDriver;
