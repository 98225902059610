import axios from "axios";
import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper } from "../../components/common/global/global";
import { useEffect, useState } from "react";
import Moderator from "./moderator";
import ModeratorCreate from "./moderator-create";
import { getModerators } from "../../services";

function Moderators() {
  const [moderators, setModerators] = useState([]);
  useEffect(() => {
  
      getModerators({})
      .then((response) => {
        setModerators(response.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <Pagename text={"Moderators"} />
      <PageWrapper>
        <>
          <ModeratorCreate />
          {moderators?.map((moderator, i) => {
            return <Moderator key={i} moderator={moderator} />;
          })}
        </>
      </PageWrapper>
    </>
  );
}

export default Moderators;
