import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper } from "../../components/common/global/global";
import { Grid4, Grid1, FlexBg } from "../../components/common/global/global";
import Search from "../../components/common/search/search";
import { getTags } from "../../services";
import TagCard from "./tag-card/tag-card";
import AddTag from "./create-tag/add-tag";

function Tags() {
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      getTags()
      .then((res) => {
        setTags(res.data);
      })
      .catch((error) => {
        localStorage.setItem("userData", "");
        navigate(0);
      });
  }, []);

  return (
    <>
      <Pagename text={"Tags"} />
      <PageWrapper>
        <Grid1>
          <FlexBg>
            <Search
              placeholder="Search tags"
              value={search}
              onChange={setSearch}
              small={true}
            />
            <AddTag />
          </FlexBg>
          <Grid4>
            {tags.map((tag, i) => {
              return <TagCard tag={tag} key={i}></TagCard>;
            })}
          </Grid4>
        </Grid1>
      </PageWrapper>
    </>
  );
}

export default Tags;
