import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { CodeCard, Input } from "./promo-code.e";
import Button from "../../components/common/button/button";
import { updatePromo, deletePromo } from "../../services";
dayjs.extend(localizedFormat);

function UpdateCard({ promo }) {
  const navigate = useNavigate();
  const fields = useFormik({
    initialValues: {
      _id: promo._id,
      name: promo.name,
      price: promo.price,
      start_date: promo.start_date,
      end_date: promo.end_date,
      count_max: promo.count_max,
      active: promo.active,
    },
  });

  const [startDate, setStartDate] = useState(
    dayjs(fields.values.start_date).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs(fields.values.end_date).format("YYYY-MM-DD")
  );

  const updatePromocode = () => {
 
      updatePromo({ ...fields.values })
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
       
          alert(error.response.data.data);
        
      });
  };

  const deletePromocode = () => {
  
      deletePromo({ _id: promo._id })
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        }
      });
  };

  // Timestamp to date
  // function format_time(s) {
  //   return new Date(s * 1e3).toISOString().slice(-13, -5);
  // }

  return (
    <CodeCard>
      <Input>
        <div>Name</div>
        <input
          placeholder="Promo code"
          className="form-control"
          autoComplete="off"
          {...fields.getFieldProps("name")}
        />
      </Input>
      <Input>
        <div>Price</div>
        <input
          placeholder="Price of the coupon"
          type="number"
          className="form-control"
          autoComplete="off"
          {...fields.getFieldProps("price")}
        />
      </Input>
      <Input>
        <div>Start</div>
        <input
          type="date"
          placeholder="Start date"
          className="form-control"
          autoComplete="off"
          value={startDate}
          onChange={(e) => {
            var date = new Date(e.target.value);
            var timestamp = date.getTime();
            fields.setFieldValue("start_date", timestamp);
            setStartDate(e.target.value);
          }}
        />
      </Input>
      <Input>
        <div>End</div>
        <input
          type="date"
          placeholder="End date"
          className="form-control"
          value={endDate}
          onChange={(e) => {
            var date = new Date(e.target.value);
            var timestamp = date.getTime();
            fields.setFieldValue("end_date", timestamp);
            setEndDate(e.target.value);
          }}
        />
      </Input>
      <Input>
        <div>Count</div>
        <input
          type="number"
          placeholder="Count max"
          value={fields.values.start_date}
          className="form-control"
          {...fields.getFieldProps("count_max")}
        />
      </Input>
      <div className="isActive">
        <label>
          <input
            type="checkbox"
            name="checkbox"
            value="value"
            checked={fields.values.active}
            onChange={() =>
              fields.setFieldValue("active", !fields.values.active)
            }
          />
          Active
        </label>
        <i onClick={deletePromocode} className="fa-regular fa-trash-can" />
      </div>
      <Button onClick={updatePromocode} text="Update" color="primary" />
    </CodeCard>
  );
}

export default UpdateCard;
