import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import CustomDropdown from "../../components/common/dropdown/dropdown";
import Modal from "../../components/common/modal/modal";
import UploadImg from "../../components/common/upload-img/upload-img";
import Button from "../../components/common/button/button";
import { Buttons } from "../../components/categories/categories.e";
import { AdCard } from "./ads.e";
import { EditItem, Label } from "../item-edit/item-edit.e";
import { updateAds, deleteAds, uploadImg } from "../../services";

function Card({ ad, inputCategories }) {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const fields = useFormik({
    initialValues: {
      _id: ad._id,
      description: ad.description,
      image: ad.image,
      start_date: ad.start_date,
      end_date: ad.end_date,
      active: ad.active,
      category_id: ad.category_id,
      category_name: ad.category_name,
    },
    enaleReinitialize: true,
  });

  const [startDate, setStartDate] = useState(
    dayjs(fields.values.start_date).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs(fields.values.end_date).format("YYYY-MM-DD")
  );

  // Update ad
  const update = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/ad/update`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    updateAds( { ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((err) => {
     
          alert(err.response.data.data);
        
      });
  };

  // Delete ad
  const deleteAd = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/ad/delete`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    deleteAds( { ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(err.response.data.data);
        }
      });
  };

  // Uplaad image to the server
  const uploadImage = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    // axios
    //   .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <>
      <AdCard onClick={() => setModal(true)}>
        <div className="img">
          <img src={ad?.image} alt={ad.description} />
        </div>
        <p>{ad?.description}</p>
      </AdCard>
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "340px" : "450px"}
          open={modal}
          setOpen={setModal}
        >
          <EditItem>
            <UploadImg
              width="100%"
              height={200}
              url={fields.values.image}
              contain="contain"
              onChange={(e) => {
                uploadImage(e);
              }}
            />
          </EditItem>
          <EditItem>
            <Label>Description</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="Enter description"
              {...fields.getFieldProps("description")}
            />
          </EditItem>
          <EditItem>
            <Label>Category</Label>
            {inputCategories && (
              <CustomDropdown
                options={inputCategories}
                defaultOption={{
                  label: fields.values.category_name,
                  value: fields.values.category_id,
                }}
                onChange={(e) => fields.setFieldValue("category_id", e.value)}
              />
            )}
          </EditItem>
          <EditItem>
            <Label>Start date</Label>
            <input
              type="date"
              placeholder="Start date"
              className="form-control"
              value={startDate}
              onChange={(e) => {
                var date = new Date(e.target.value);
                var timestamp = date.getTime();
                fields.setFieldValue("start_date", timestamp);
                setStartDate(e.target.value);
              }}
            />
          </EditItem>
          <EditItem>
            <Label>End date</Label>
            <input
              type="date"
              placeholder="End date"
              className="form-control"
              value={endDate}
              onChange={(e) => {
                var date = new Date(e.target.value);
                var timestamp = date.getTime();
                fields.setFieldValue("end_date", timestamp);
                setEndDate(e.target.value);
              }}
            />
          </EditItem>
          <EditItem>
            <label>
              <input
                type="checkbox"
                name="checkbox"
                value="value"
                checked={fields.values.active}
                onChange={() =>
                  fields.setFieldValue("active", !fields.values.active)
                }
              />
              Active
            </label>
          </EditItem>
          <Buttons>
            <Button
              onClick={deleteAd}
              color="danger"
              text={<i className="fa-solid fa-trash" />}
            />
            <Button
              onClick={() => setModal(false)}
              text="Close"
              color="light"
            />
            <Button onClick={update} text="Update" color="primary" />
          </Buttons>
        </Modal>
      )}
    </>
  );
}

export default Card;
