import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: right;
`;

export const CodeCard = styled.div`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 16px;
  border-radius: 5px;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  margin-top: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  h5 {
    font-size: 16px;
    margin: 0 0 7px 0;
  }
  label {
    cursor: pointer;
  }
  .isActive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text);
    i {
      color: var(--red);
      cursor: pointer;
    }
  }
`;

export const Input = styled.div`
  display: grid;
  grid-template-columns: 68px 5fr auto;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  overflow: hidden;
  div {
    padding: 8px 14px;
    background-color: var(--light);
    border-right: 1px solid var(--input-border);
    color: var(--dark);
    text-align: center;
  }
  input {
    border: none;
  }
`;
