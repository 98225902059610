import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 20px;
`;

export const AddButton = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`;
