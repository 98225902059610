import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../components/common/button/button";
import Modal from "../../components/common/modal/modal";
import { CodeCard, Input } from "./promo-code.e";
import { createPromo } from "../../services";

function CreateCode() {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const fields = useFormik({
    initialValues: {
      name: "",
      price: "",
      start_date: "",
      end_date: "",
      count_max: "",
      active: true,
    },
  });

  const createPromocode = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/promocode/create",
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      createPromo({ ...fields.values })
      .then((res) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      });
  };

  return (
    <>
      <Button onClick={() => setModal(true)} text="Create" color="primary" />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "350px" : "500px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <CodeCard>
            <Input>
              <div>Name</div>
              <input
                placeholder="Promo code"
                className="form-control"
                autoComplete="off"
                {...fields.getFieldProps("name")}
              />
            </Input>
            <Input>
              <div>Price</div>
              <input
                placeholder="Price of the coupon"
                type="number"
                className="form-control"
                autoComplete="off"
                {...fields.getFieldProps("price")}
              />
            </Input>
            <Input>
              <div>Start</div>
              <input
                type="date"
                placeholder="Start date"
                className="form-control"
                autoComplete="off"
                onChange={(e) => {
                  var date = new Date(e.target.value);
                  var timestamp = date.getTime();
                  fields.setFieldValue("start_date", timestamp);
                }}
              />
            </Input>
            <Input>
              <div>End</div>
              <input
                type="date"
                placeholder="End date"
                className="form-control"
                onChange={(e) => {
                  var date = new Date(e.target.value);
                  var timestamp = date.getTime();
                  fields.setFieldValue("end_date", timestamp);
                }}
              />
            </Input>
            <Input>
              <div>Count</div>
              <input
                type="number"
                placeholder="Count max"
                className="form-control"
                {...fields.getFieldProps("count_max")}
              />
            </Input>
            <div className="isActive">
              <label>
                <input
                  type="checkbox"
                  name="checkbox"
                  value="value"
                  checked={fields.values.active}
                  onChange={() =>
                    fields.setFieldValue("active", !fields.values.active)
                  }
                />
                Active
              </label>
            </div>
            <Button onClick={createPromocode} text="Create" color="primary" />
          </CodeCard>
        </Modal>
      )}
    </>
  );
}

export default CreateCode;
