import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { getSettings , updateSetting} from "../../services";
// import { ToastContainer, toast } from "react-toastify";
import Pagename from "../../components/common/pagename/pagename";
import Button from "../../components/common/button/button";
import useGetFetch from "../../hooks/useGetFetch";
import { EditItem, Label } from "../item-edit/item-edit.e";
import { Wrapper, Grid3, TextRight } from "./settings.e";
import {
  Card,
  CardBody,
  CardHead,
  PageWrapper,
} from "../../components/common/global/global";

function Settings() {
  const [settings, setSettings]=useState()
  const [loaded, setLoaded]=useState(false)
  const navigate = useNavigate();
useEffect(()=>{
  setLoaded(true);
  getSettings().then((res)=>{
    setSettings(res.data);
  }).catch((err)=>err).finally(()=>{
    setLoaded(false)
  })

},[])

  const fields = useFormik({
    enableReinitialize: true,
    initialValues: {
      delivery: settings ? settings?.delivery : "",
      static_delivery: settings ? settings.static_delivery : "",
      min_cost: settings ? settings?.min_cost : "",
      facebook_link: settings ? settings?.facebook_link : "",
      instagram_link: settings ? settings?.instagram_link : "",
      telegram_link: settings ? settings?.telegram_link : "",
      _id: settings ? settings?._id : "",
      organization: settings ? settings?.organization : "",
      __v: settings ? settings?.__v : "",
      email: settings ? settings?.email : "",
      phone_number: settings ? settings?.phone_number : "",
    },
  });

  // Update item
  const onUpdateSetting = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/set/setting`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    updateSetting({ ...fields.values })
      .then((response) => {
        navigate(0);
      });
  };

  return (
    <>
      <Pagename text={"Settings"} />
      <PageWrapper>
        <Wrapper style={loaded ? { opacity: "0" } : { opacity: 1 }}>
          <Card>
            <CardHead>
              <h2>Store Settings</h2>
            </CardHead>
            <CardBody>
              <Grid3>
                <EditItem>
                  <Label>Delivery fee per km</Label>
                  <input
                    className="form-control"
                    type="number"
                    autoComplete="off"
                    {...fields.getFieldProps("delivery")}
                  />
                </EditItem>
                <EditItem>
                  <Label>Static Delivery</Label>
                  <input
                    className="form-control"
                    type="number"
                    autoComplete="off"
                    {...fields.getFieldProps("static_delivery")}
                  />
                </EditItem>
                <EditItem>
                  <Label>Min Cost</Label>
                  <input
                    className="form-control"
                    type="number"
                    autoComplete="off"
                    {...fields.getFieldProps("min_cost")}
                  />
                </EditItem>
              </Grid3>
              <EditItem>
                <Label>
                  <i
                    style={{ color: "rgb(66 103 178)" }}
                    className="fa-brands fa-facebook"
                  />
                  Facebook
                </Label>
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  {...fields.getFieldProps("facebook_link")}
                />
              </EditItem>
              <EditItem>
                <Label>
                  <i
                    style={{ color: "#C13584" }}
                    className="fa-brands fa-instagram"
                  />
                  Instagram
                </Label>
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  {...fields.getFieldProps("instagram_link")}
                />
              </EditItem>
              <EditItem>
                <Label>
                  <i
                    style={{ color: "#0088cc" }}
                    className="fa-brands fa-telegram"
                  />
                  Telegram
                </Label>
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  {...fields.getFieldProps("telegram_link")}
                />
              </EditItem>
              <EditItem>
                <Label>
                  <i
                    style={{ color: "#34A853" }}
                    className="fa-solid fa-envelope"
                  />
                  Email
                </Label>
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  {...fields.getFieldProps("email")}
                />
              </EditItem>
              <EditItem>
                <Label>
                  <i
                    style={{ color: "indigo" }}
                    className="fa-solid fa-phone"
                  />
                  Phone number
                </Label>
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  {...fields.getFieldProps("phone_number")}
                />
              </EditItem>
              <TextRight>
                <Button onClick={onUpdateSetting} text="Update" color="primary" />
              </TextRight>
            </CardBody>
          </Card>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default Settings;
