import axios from "axios";
const BASE_URL="https://beeto.in1.uz/api"

export const request =axios.create({
    baseURL:BASE_URL,
    headers:{"Content-Type":"application/json"}
})

// request.defaults.headers.timezone = new Date().getTimezoneOffset();


request.interceptors.request.use((config)=>{


   const token = JSON.parse(localStorage.getItem("userData")).token;
   if(token){
    config.headers.Authorization = `Bearer ${token}`;
   }
   
   return config;
    
}, errorHandler)

request.interceptors.response.use((response) => {
    return response.data;
  }, errorHandler);


export function errorHandler(error) {
    // Using toJSON you get an object with more information about the HTTP error
    // const errObj = error.toJSON();
    if (error.response) {
      // server responded with a status code that falls out of the range of 2xx
  
      if (error.response?.data?.code === 401) {
       
            localStorage.removeItem("userData");
            window.location.replace("/auth/login");
            localStorage.clear();
         
      }
  
      // console.error(error.response);
      // toast({ title: error.message });
      return Promise.reject(error.response);
    }
    if (error.request) {
  
      return Promise.reject(error.request);
    }

  
    return Promise.reject(error);
  }