import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  transition: 0.3s;
  padding: 30px;
`;

export const MapWrapper = styled.div`
  border-radius: 24px;
  width: 100%;
`;

export const RegionCard = styled.div`
  border-radius: 12px;
  padding: 20px;
  background-color: var(--pagename);
  cursor: pointer;
  transition: 0.3s box-shadow ease-in-out;
  img {
    width: 100%;
  }
  p {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    color: var(--text-body);
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  }
`;
