import { useState, useEffect } from "react";
import Row from "./row";
import Loader from "../../components/common/loader/loader";
import Pagename from "../../components/common/pagename/pagename";
import Navigation from "../../components/common/navigation/navigation";
import { PageWrapper } from "../../components/common/global/global";
import { Wrapper } from "../orders/orders.e";
import { getClients } from "../../services/clients";
// import useAxiosFetch from "../../hooks/useAxiosFetch";
import Search from "../../components/common/search/search";

function Clients() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [clients, setClients] = useState();

  useEffect(() => {
    // search !== "" &&
    setTimeout(() => {
      setLoaded(true);
      getClients({ limit: 10, page: page, search: search })
        .then((res) => {
          setClients(res.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoaded(false);
        });
    }, 2000);
  }, [page, search]);

  return (
    <>
      <Pagename text={"Clients"} />
      <PageWrapper>
        <Wrapper>
          <div className="search">
            <Search
              value={search}
              onChange={setSearch}
              small={true}
              placeholder="Search ..."
            />
          </div>
          {loaded ? (
            <Loader />
          ) : (
            <>
              <div className="responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th width="14%">First Name</th>
                      <th width="14%">Last Name</th>
                      <th width="14%">Phone Number</th>
                      <th width="10%">Status</th>
                      <th width="10%">Total Orders</th>
                      <th width="15%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients?.data?.map((client, i) => {
                      return <Row key={i} client={client} />;
                    })}
                  </tbody>
                </table>
              </div>
              <Navigation
                current={page}
                total={Math.round(clients?.total / 10)}
                next={() => setPage(page + 1)}
                prev={() => setPage(page - 1)}
                onChange={setPage}
              />
            </>
          )}
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default Clients;
