import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  transition: 0.3s;
  animation: 0.4s anime ease-in-out;
  @keyframes anime {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .personal-avatar {
    width: 50px !important;
  }
`;

export const AdCard = styled.div`
  padding: 12px;
  border-radius: 12px;
  background-color: var(--pagename);
  cursor: pointer;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: contain;
    display: block;
  }
  p {
    margin-top: 12px;
    color: var(--text);
    font-size: 14px;
    padding-top: 12px;
    line-height: 1.4;
  }
  .img {
    height: 150px;
  }
`;

export const Right = styled.div`
  text-align: right;
  margin-bottom: 30px;
`;
