import { useState, useEffect } from "react";
import Loader from "../../components/common/loader/loader";
import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper } from "../../components/common/global/global";
import Navigation from "../../components/common/navigation/navigation";
// import useAxiosFetch from "../../hooks/useAxiosFetch";
import Row from "./row";
import Search from "../../components/common/search/search";
import { getOrders } from "../../services/orders";
import { Wrapper, Tabs, Tab } from "./orders.e";

const states = [
  {
    state: "new-order",
    text: `New`,
    icon: <i className="fa-regular fa-store" />,
    total: "new",
  },
  {
    state: "active",
    text: "Active",
    icon: <i className="fa-regular fa-box-circle-check" />,
    total: "active",
  },
  {
    state: "in-progress",
    text: "In progress",
    icon: <i className="fa-regular fa-truck-moving" />,
    total: "inProgress",
  },
  {
    state: "completed",
    text: "Completed",
    icon: <i className="fa-regular fa-circle-check" />,
    total: "completed",
  },
  {
    state: "cancelled",
    text: "Cancelled",
    icon: <i className="fa-regular fa-circle-xmark"></i>,
    total: "cancelled",
  },
];

function Orders() {
  const [total, setTotal] = useState({
    new: 0,
    active: 0,
    inProgress: 0,
    completed: 0,
    cancelled: 0,
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [state, setState] = useState(localStorage.getItem("order_state"));
  const [loading, setLoading] = useState(true);
  const [orders, setOrders]=useState([])
  const currentTotal =
    state === "new-order"
      ? total.new
      : state === "active"
      ? total.active
      : state === "in-progress"
      ? total.inProgress
      : state === "completed"
      ? total.completed
      : total.cancelled;

  // const { data: orders, loaded } = useAxiosFetch(
  //   "https://beeto.in1.uz/api/moderator-order/get-pagin",
  //   { limit: 10, page: page, state: state, search: search }
  // );

  useEffect(() => {
    setPage(1);
  }, [state]);

  useEffect(() => {
    setLoading(true)
    getOrders({ limit: 10, page: page, state: state, search: search })
      .then((response) => {
        console.log("respo0nse", response)
        setOrders(response)
        setTotal({
          new: response?.data.new_orders,
          active: response?.data?.active,
          inProgress: response?.data?.in_progress,
          completed: response?.data?.completed,
          cancelled: response?.data?.cancelled,
        });
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error fetching orders:", error);
      }).finally(() => {
        setLoading(false); // Set loading to false after the request is complete (regardless of success or failure)
      });;
  }, [page, search, state]);
  
  console.log("orders", orders)
  
  return (
    <>
      <Pagename text={"Orders"} />
      <PageWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Wrapper>
            <Tabs>
              {states.map((menu, i) => {
                return (
                  <Tab
                    key={i}
                    onClick={() => {
                      setState(menu.state);
                      localStorage.setItem("order_state", menu.state);
                    }}
                    className={state === menu.state ? "active" : ""}
                  >
                    {menu.icon}
                    <span>{menu.text}</span>
                    {total[menu.total] > 0 && <div>{total[menu.total]}</div>}
                  </Tab>
                );
              })}
              <Search
                value={search}
                onChange={setSearch}
                small={true}
                placeholder="Search ..."
              />
            </Tabs>
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="address-th">Address</th>
                    <th>Amount</th>
                    <th>Client Name</th>
                    <th>Client Phone</th>
                    <th className="payment">Payment</th>
                    {state === "in-progress" && <th>Accept Time</th>}
                    {state === "cancelled" && <th>Cancelled Time</th>}
                    {state === "completed" && <th>Completed Time</th>}
                    <th className="status">Status</th>
                    <th style={{ paddingLeft: "15px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.data?.data?.map((item, i) => {
                    return <Row stateNow={state} key={i} item={item} />;
                  })}
                </tbody>
              </table>
            </div>
            <Navigation
              current={page}
              total={Math.ceil(currentTotal / 10)}
              next={() => setPage(page + 1)}
              prev={() => setPage(page - 1)}
              onChange={setPage}
            />
          </Wrapper>
        )}
      </PageWrapper>
    </>
  );
}

export default Orders;
