import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  background-color: var(--header);
  text-align: center;
`;

export const Title = styled.h4`
  font-size: 18px;
  color: var(--indigo);
  margin: 15px 0;
  font-weight: 500;
  margin-bottom: 20px;
`;
