import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SubCategory, Buttons, Img } from "../categories.e";
import { EditItem, Label } from "../../../pages/item-edit/item-edit.e";
import Button from "../../common/button/button";
import Modal from "../../common/modal/modal";
import MultiSelect from "../../common/multi-select/multi-select";
import CustomDropdown from "../../common/dropdown/dropdown";
import ImageCrop from "../../common/upload-img/image-crop/image-crop";
import { uploadImg, deleteCategory, updateCategories } from "../../../services";

function Subcategory({ subcategory, types, type, tags }) {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let tags = [];
    subcategory.tags &&
      subcategory.tags.map((tag) => {
        return tags.push({ value: tag?.tag_id, label: tag?.tag_name });
      });
    setSelected(tags);
  }, [subcategory]);

  useEffect(() => {
    const formatted = [];
    selected.map((i) => {
      return formatted.push({ tag_id: i.value });
    });
    fields.setFieldValue("tags", formatted);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // Fields control
  const fields = useFormik({
    initialValues: {
      _id: subcategory._id,
      name: subcategory.name,
      tags: subcategory.tags && subcategory.tags,
      type: subcategory.type,
      image: subcategory.image,
      image_size: subcategory.image_size,
      show_on_bot: subcategory.show_on_bot,
    },
  });

  const onDeleteCategory = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/category/moderator/delete`,
    //     { _id: subcategory._id },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      deleteCategory( { _id: subcategory._id })
      .then((response) => {
        setModal(false);
        navigate(0);
      });
  };

  // Avatar upload to server
  const uploadImage = (bodyFormData) => {
    // axios
    //   .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  // Update item
  const updateCategory = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/category/moderator/update`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      updateCategories( { ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      });
  };

  return (
    <>
      <SubCategory
        onClick={(e) => {
          setModal(true);
          e.stopPropagation();
        }}
      >
        {<span>{subcategory.name}</span>}
        <button>
          <i className="fa-regular fa-pen-clip" />
        </button>
      </SubCategory>
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "350px" : "600px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <Img>
            <ImageCrop
              width="50%"
              height="130px"
              url={fields.values.image}
              onChange={uploadImage}
              cropWidth={
                fields.values.image_size === "3x1" ||
                fields.values.image_size === "2x1"
                  ? 300
                  : 150
              }
              cropHeight={
                fields.values.image_size === "3x1" ||
                fields.values.image_size === "2x1"
                  ? 150
                  : 150
              }
            />
          </Img>
          <EditItem>
            <Label>Category Name</Label>
            <input
              className="form-control"
              type="text"
              {...fields.getFieldProps("name")}
              disabled={subcategory.name === "Other"}
            />
          </EditItem>
          <EditItem>
            <Label>Category Type</Label>
            <CustomDropdown
              options={types}
              defaultOption={{
                label: type,
                value: type,
              }}
              onChange={(e) => fields.setFieldValue("type", e.value)}
              isDisabled={subcategory.name === "Other"}
            />
          </EditItem>
          <EditItem>
            <Label>Tags</Label>
            <MultiSelect
              options={tags && tags}
              selected={selected}
              setSelected={setSelected}
              isDisabled={subcategory.name === "Other"}
            />
          </EditItem>
          <EditItem>
            <Label>Image size</Label>
            <CustomDropdown
              options={[
                { label: "1x1", value: "1x1" },
                { label: "2x1", value: "2x1" },
                { label: "3x1", value: "3x1" },
              ]}
              defaultOption={{
                label: fields.values.image_size,
                value: fields.values.image_size,
              }}
              onChange={(e) => {
                fields.setFieldValue("image_size", e.value);
              }}
            />
          </EditItem>
          <EditItem>
            <Label>Active</Label>
            <input
              type="checkbox"
              name="show_on_bot"
              id="show_on_bot"
              checked={fields.values.show_on_bot}
              onChange={() =>
                fields.setFieldValue("show_on_bot", !fields.values.show_on_bot)
              }
            />
          </EditItem>

          <Buttons>
            {subcategory.name !== "Other" && (
              <Button
                onClick={onDeleteCategory}
                text={<i className="fa-solid fa-trash" />}
                color="danger"
              />
            )}
            <div>
              <Button
                onClick={() => setModal(false)}
                text="Cancel"
                color="light"
              />
              <Button onClick={updateCategory} text="Save" color="primary" />
            </div>
          </Buttons>
        </Modal>
      )}
    </>
  );
}

export default Subcategory;
