import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import CustomDropdown from "../../components/common/dropdown/dropdown";
import { Buttons } from "../../components/categories/categories.e";
import Button from "../../components/common/button/button";
import Modal from "../../components/common/modal/modal";
import Badge from "../../components/common/badge/badge";
import { EditItem, Label } from "../item-edit/item-edit.e";
import UploadImg from "../../components/common/upload-img/upload-img";
import { onUpdateClient, uploadImg } from "../../services/clients";
import { Img } from "./clients.e";

function Row({ client }) {
  const {
    _id,
    first_name,
    last_name,
    phone_number,
    status,
    total_orders,
    image,
  } = client;
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const fields = useFormik({
    initialValues: {
      _id: _id,
      first_name: first_name ? first_name : "",
      last_name: last_name ? last_name : "",
      phone_number: phone_number,
      status: status,
      image: image ? image : "",
    },
  });

  const updateClient = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/client-update",
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    
    onUpdateClient({ ...fields.values }).then((res) => {
        navigate(0);
      })
      .catch((error) => {
       
      });
  };

  // Avatar upload to server

  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <tr>
      <td style={{ paddingLeft: "20px" }}>
        {first_name ? first_name : <span>To'ldirilmagan</span>}
      </td>
      <td>{last_name ? last_name : <span>To'ldirilmagan</span>}</td>
      <td>{phone_number}</td>
      <td>
        <Badge
          text={status === "active" ? "Active" : "Blocked"}
          soft={status === "active" ? "success" : "danger"}
          uppercase
        />
      </td>
      <td>{total_orders} ta</td>
      <td>
        <Button onClick={openModal} text="Edit" soft="primary" size="sm" />
        {modal && (
          <Modal
            width={window.innerWidth < 800 ? "350px" : "550px"}
            setOpen={closeModal}
            open={modal}
          >
            <Img>
              <UploadImg
                width="150px"
                height="150px"
                url={fields.values.image}
                onChange={(e) => {
                  uploadAvatar(e);
                }}
              />
            </Img>
            <EditItem>
              <Label>First Name</Label>
              <input
                className="form-control"
                type="text"
                autoComplete="off"
                placeholder="To'ldirilmagan"
                {...fields.getFieldProps("first_name")}
              />
            </EditItem>
            <EditItem>
              <Label>Last Name</Label>
              <input
                className="form-control"
                placeholder="To'ldirilmagan"
                autoComplete="off"
                type="text"
                {...fields.getFieldProps("last_name")}
              />
            </EditItem>
            <EditItem>
              <Label>Phone Number</Label>
              <input
                className="form-control"
                type="text"
                autoComplete="off"
                {...fields.getFieldProps("phone_number")}
              />
            </EditItem>
            <EditItem>
              <Label>Status</Label>
              <CustomDropdown
                options={[
                  { label: "active", value: "active" },
                  { label: "block", value: "block" },
                ]}
                defaultOption={{
                  label: fields.values.status,
                  value: fields.values.status,
                }}
                onChange={(e) => fields.setFieldValue("status", e.value)}
              />
            </EditItem>
            <Buttons>
              <Button onClick={closeModal} text="Cancel" color="light" />
              <Button onClick={updateClient} text="Save" color="primary" />
            </Buttons>
          </Modal>
        )}
      </td>
    </tr>
  );
}

export default Row;
