import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .title {
    width: 300px;
    display: grid;
    grid-template-columns: 40% 80%;
    align-items: center;
    justify-content: space-between !important;
    margin-left: 6px;
    p {
      margin-bottom: 4px;
      font-weight: 400;
      padding-right: 10%;
      line-height: 1.7;
      padding-right: 5%;
    }
    span {
      font-weight: 300;
      font-size: 12px;
      color: #878a99;
    }
  }
  .custom-map {
    width: 100% !important;
    height: 300px !important;
    transform: translate(-200px);
  }
  table {
    th {
      font-size: 13px !important;
    }
    * {
      font-size: 14px !important;
    }
  }
  h5 {
    color: var(--heading) !important;
  }
`;

export const ProductImg = styled.div`
  padding: 0px 12px;
  img {
    height: 70px;
    border-radius: 8px;
  }
`;

export const OrderPrice = styled.div`
  padding: 20px;
  border-top: 1px dashed var(--border-color);
  display: grid;
  grid-template-columns: 32%;
  grid-gap: 12px;
  justify-content: right;
  div {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    &.total {
      font-weight: 700;
    }
  }
`;

export const Timeline = styled.div`
  display: flex;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 32px;
    left: 15px;
    height: 80%;
    width: 2px;
    border-left: 2px dashed var(--border-color);
  }
  &.not-active {
    padding: 8px 0;
  }
  &.not-active::after {
    top: 40px;
  }
  &.no-line::after {
    display: none;
  }
`;

export const TimelineIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  border-radius: 50%;
  color: #fff;
  &.not-active {
    background-color: rgb(243, 246, 249);
    color: var(--green);
  }
`;

export const TimelineInfo = styled.div`
  padding: 0 15px;
  padding-top: 3.5px;
`;

export const TimelineHead = styled.div`
  font-weight: 600;
  color: var(--text-body);
  font-size: 15px;
`;

export const TimelineDetail = styled.div`
  margin: 15px 0;
  margin-top: 8px;
  color: var(--heading);
  h6 {
    font-size: 0.8125rem;
    margin-bottom: 4px;
    span {
      color: #878a99;
    }
  }
  p {
    color: #878a99;
  }
`;

export const MapWrapper = styled.div`
  border-radius: 24px;
  width: 100%;
`;

export const Left = styled.div`
  width: 72%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  width: 25%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Customer = styled.div`
  display: grid;
  grid-gap: 0.9rem;
  grid-template-columns: 48px auto;
  align-items: center;
  img {
    width: 48px;
    border-radius: 0.25rem;
  }
  p {
    b {
      color: var(--heading);
      font-size: 15px;
      font-weight: 500;
      display: block;
    }
    span {
      color: #878a99;
      font-size: 12px;
    }
  }
`;
