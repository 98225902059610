import { useContext } from "react";
import { MenuSizeContext } from "../../../context/menuSize";

function MenuResizer() {
  const { toggleMenu, size } = useContext(MenuSizeContext);
  return (
    <i
      onClick={() => toggleMenu(!size)}
      className="fa-solid fa-bars-staggered"
    />
  );
}

export default MenuResizer;
