import { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Title, Wrapper } from "./tag-card.e";
import { Buttons } from "../../../components/categories/categories.e";
import { EditItem, Label } from "../../item-edit/item-edit.e";
import Button from "../../../components/common/button/button";
import Modal from "../../../components/common/modal/modal";
import { updateTag, deleteTag } from "../../../services";
import Icon from "../../../components/common/Icon/Icon";
import Trash from "../../../assets/images/trash.svg"


function TagCard({ tag }) {
  const [view, setView] = useState(false);
  const navigate = useNavigate();

  const fields = useFormik({
    initialValues: {
      _id: tag._id,
      name: tag.name,
    },
  });

  // Update Tag
  const update = () => {
 
      updateTag({ ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((error) => {
        localStorage.setItem("userData", "");
        navigate(0);
      });
  };

  // Delete Tag
  const onDeleteTag = () => {
      deleteTag({ _id: tag._id })
      .then((res) => {
        setView(false);
        navigate(0);
      });
  };

  return (
    <Wrapper>
      <Title>{tag.name}</Title>
      <Button onClick={() => setView(true)} full color="light" text="Edit" />
      {view && (
        <Modal
          width={window.innerWidth < 800 ? "340px" : "550px"}
          setOpen={() => setView(false)}
          open={view}
        >
          <EditItem>
            <Label>Name</Label>
            <input
              type="text"
              className="form-control"
              {...fields.getFieldProps("name")}
            />
          </EditItem>
          <Buttons>
            <Button
              onClick={onDeleteTag}
              // text={<i className="fa-solid fa-trash" />}
        text={<Icon file={Trash}   title="trash"/>}

              // color="danger"
            />
            <div>
              <Button
                onClick={() => setView(false)}
                text="Cancel"
                color="light"
              />
              <Button onClick={update} text="Save" color="primary" />
            </div>
          </Buttons>
        </Modal>
      )}
    </Wrapper>
  );
}

export default TagCard;
