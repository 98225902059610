import { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Fullscreen from "react-fullscreen-crossbrowser";
import Header from "./components/header/header";
import Menu from "./components/menu/menu";
import {
  Container,
  Flex,
  GlobalStyle,
} from "./components/common/global/global";
import { DarkModeContext } from "./context/darkMode";
import { FullScreenContext } from "./context/fullScreen";
import { AuthContext } from "./context/auth";

// Pages
import Reports from "./pages/reports/reports";
import Orders from "./pages/orders/orders";
import Banners from "./pages/banners/banners";
import Clients from "./pages/clients/clients";
import Drivers from "./pages/drivers/drivers";
import Organizations from "./pages/organizations/organizations";
import Moderators from "./pages/moderators/moderators";
import Products from "./pages/products/products";
import News from "./pages/news/news";
import Regions from "./pages/regions/regions";
import Settings from "./pages/settings/settings";
import Auth from "./pages/auth/auth";
import Categories from "./pages/categories/categories";
import ItemEdit from "./pages/item-edit/item-edit";
import Tags from "./pages/tags/tags";
import OrderDetail from "./pages/order-detail/order-detail";
import NewProduct from "./pages/new-product/new-product";
import NewCategory from "./pages/new-category/new-category";
import OrderEdit from "./pages/order-edit/order-edit";
import PromoCode from "./pages/promo-code/promo-code";
import Ads from "./pages/ads/ads";
import CreateNews from "./pages/news/create-news/create-news";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { full } = useContext(FullScreenContext);
  const { isLogged } = useContext(AuthContext);

  useEffect(() => {
    if (window.fullScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    localStorage.removeItem("products_tab");
  }, []);

  return (
    <>
      <Fullscreen enabled={full}>
        <BrowserRouter>
          <GlobalStyle isFull={full} isLight={darkMode} />
          {isLogged ? (
            <Flex>
              <Menu />
              <Container>
                <Header />
                <Routes>
                  <Route path="/" element={<Reports />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/drivers" element={<Drivers />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/banners" element={<Banners />} />
                  <Route path="/regions" element={<Regions />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/tags" element={<Tags />} />
                  <Route path="/organizations" element={<Organizations />} />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/products/:query" element={<Products />} />
                  <Route path="/products/tag/:tag" element={<Products />} />
                  <Route
                    path="/products/:query/:cat_id"
                    element={<Products />}
                  />
                  <Route path="/item/edit/:product_id" element={<ItemEdit />} />
                  <Route path="/order-edit/:order_id" element={<OrderEdit />} />
                  <Route path="/order/:order_id" element={<OrderDetail />} />
                  <Route path="/moderators" element={<Moderators />} />
                  <Route path="/new-products" element={<NewProduct />} />
                  <Route path="/create-news" element={<CreateNews />} />
                  <Route
                    path="/create-news/:news_id"
                    element={<CreateNews />}
                  />
                  <Route path="/new-category" element={<NewCategory />} />
                  <Route path="/promo-code" element={<PromoCode />} />
                  <Route path="/ads" element={<Ads />} />
                  <Route path="*" element={<Reports />} />
                </Routes>
              </Container>
            </Flex>
          ) : (
            <Auth />
          )}
        </BrowserRouter>
      </Fullscreen>
    </>
  );
}

export default App;
