import { YMaps, Map, Polygon, ZoomControl } from "react-yandex-maps";

function EditablePolygon({ coords, onChange }) {
  const draw = (ref) => {
    ref.editor.startDrawing();
    ref.editor.events.add("vertexadd", (event) => {
      onChange(event.originalEvent.target.geometry._coordPath._coordinates[0]);
    });
  };

  const mapState = {
    center:
      coords.length > 1 ? coords[0] : [41.571978272763445, 69.97681840995452],
    zoom: 12,
  };

  return (
    <YMaps query={{ apikey: "a611d201-19b9-4184-98c3-e7d6c4de6c1d" }}>
      <Map
        height="500px"
        width="100%"
        defaultState={mapState}
        modules={["geoObject.addon.editor"]}
      >
        <Polygon
          instanceRef={(ref) => ref && draw(ref)}
          geometry={coords && coords?.length > 1 ? [coords] : []}
          options={{
            editorDrawingCursor: "crosshair",
            editorMaxPoints: 500000,
            fillColor: "#00FF00",
            strokeColor: "#0000FF",
            strokeWidth: 5,
          }}
        />
        <ZoomControl options={{ float: "bottom" }} />
      </Map>
    </YMaps>
  );
}

export default EditablePolygon;
