import styled from "styled-components";

export const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  // img {
  //   width: 100%;
  //   height: 150px;
  // }
  .news-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 15px;
  }
  &:hover button {
    display: block;
  }
  h2 {
    text-align: center;
    margin: 20px 0;
    font-size: 18px;
  }
  @media (max-width: 800px) {
    img {
      height: 60px !important;
    }
  }
`;
