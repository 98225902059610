import { request } from "../api/axios";

export const getTags = (data) => {
    return request({ method: "post", url: "/tag/moderator/get", data });
  };
  export const updateTag = (data) => {
    return request({ method: "post", url: "/tag/update", data });
  };
  export const deleteTag= (data) => {
    return request({ method: "post", url: "/tag/delete", data });
  };