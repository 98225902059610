import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { DateRangePicker } from "react-date-range";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import ClickAwayListener from "react-click-away-listener";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Button from "../common/button/button";
import CardAnimate from "./card-animate/card-animate";
import { Grid4 } from "../common/global/global";
import { Card, Relative, Greeting, Wrapper } from "./reports-section.e";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import Icon from "../common/Icon/Icon";
import Dollar from "../../assets/images/dollar.svg";
import ShopBag from "../../assets/images/shopping.svg";
import Check from "../../assets/images/check-circle.svg";
import Money from "../../assets/images/wavy-money-bill.svg";

dayjs.extend(localizedFormat);

function ReportsSection() {
  const [date, setDate] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const report_data = {
    date: [],
    orders: [],
    completed: [],
    cancelled: [],
  };

  useEffect(() => {
    localStorage.setItem("order_state", "new-order");
  }, []);

  const { data: reports, loaded } = useAxiosFetch(
    "https://beeto.in1.uz/api/reports",
    {
      from_date: state[0]
        ? dayjs(state[0]?.startDate).format("YYYY-MM-DD")
        : "2022-04-20",
      to_date: state[0]
        ? dayjs(state[0]?.endDate).format("YYYY-MM-DD")
        : "2022-04-27",
      type: "total_orders",
    }
  );

  reports?.chart_data?.map((item) => {
    report_data.date.push(item[0]);
    report_data.orders.push(item[1]);
    report_data.completed.push(item[2]);
    report_data.cancelled.push(item[3]);
  });

  const data = {
    series: [
      {
        name: "New Orders",
        data: report_data?.orders,
      },
      {
        name: "Completed Orders",
        data: report_data?.completed,
      },
      {
        name: "Cancelled Orders",
        data: report_data?.cancelled,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: report_data?.date,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      noData: {
        text: "Maybe there are no orders ...",
      },
    },
  };

  return (
    <ClickAwayListener onClickAway={() => setDate(false)}>
      <Wrapper style={!loaded ? { opacity: 0 } : { opacity: 1 }}>
        <Greeting>
          <p>
            Good afternoon!{" "}
            {JSON.parse(localStorage.getItem("userData")).username}
            <span>Here's what's happening with your store today.</span>
          </p>
          <Button
            onClick={() => setDate(!date)}
            soft="primary"
            text={`${dayjs(state[0].startDate).format(
              "MMM DD, YYYY"
            )} to ${dayjs(state[0].endDate).format("MMM DD, YYYY")}`}
          />
        </Greeting>
        {date && (
          <Relative>
            {window.innerWidth > 800 && (
              <DateRangePicker
                className="date"
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                maxDate={new Date()}
              />
            )}
            {window.innerWidth < 800 && (
              <DateRangePicker
                className="date mobile"
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
                maxDate={new Date()}
              />
            )}
          </Relative>
        )}

        <Grid4>
          <CardAnimate
            icon={<Icon file={Dollar} title="dollar" />}
            iconBg="rgba(10,179,156,.18)"
            iconColor="#0ab39c"
            count={reports && reports.income}
            title="Total earnings"
          />
          <CardAnimate
            icon={<Icon file={ShopBag} title="dollar" />}
            iconBg="rgba(41,156,219,.18)"
            iconColor="rgb(41,156,219)"
            count={reports && reports.total_orders}
            title="Orders"
          />
          <CardAnimate
            icon={<Icon file={Check} title="dollar" />}
            iconBg="rgba(64,81,137,.18)"
            iconColor="rgb(64,81,137)"
            count={reports && reports.total_completed_orders}
            title="Completed Orders"
          />
          <CardAnimate
            icon={<Icon file={Money} title="dollar" />}
            iconBg="rgba(247,184,75,.18)"
            iconColor="rgb(247,184,75)"
            count={
              reports &&
              Math.floor((reports.payment_type_cash * 100) / reports.income)
            }
            count2={
              reports &&
              100 -
                Math.floor((reports.payment_type_cash * 100) / reports.income)
            }
            title="Type of payment"
            prefix="%"
          />
        </Grid4>
        <Card>
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="area"
            width="98%"
            height={380}
          />
        </Card>
      </Wrapper>
    </ClickAwayListener>
  );
}

export default ReportsSection;
