import Pagename from "../../components/common/pagename/pagename";
import { useState, useEffect } from "react";
import { PageWrapper, Grid3 } from "../../components/common/global/global";
import NewsCard from "./news-card/news-card";
import Loader from "../../components/common/loader/loader";
import Button from "../../components/common/button/button";
import { useNavigate } from "react-router-dom";
import { getNews } from "../../services";

function News() {
  const navigate = useNavigate();
  const [news, setNews] = useState();
  const [loaded, setLoaded] = useState();
  useEffect(() => {
    setLoaded(true);
    getNews({ page: 1, limit: 20 })
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoaded(false);
      });
  }, []);

  return (
    <>
      <Pagename text={"News"} />
      {loaded && <Loader />}
      <PageWrapper style={loaded ? { opacity: 0 } : { opacity: 1 }}>
        <div style={{ textAlign: "right", marginBottom: "20px" }}>
          <Button
            onClick={() => navigate("/create-news")}
            outline
            color="primary"
            text="Add News"
          />
        </div>
        <Grid3>
          {news?.data.map((n, i) => {
            return <NewsCard key={i} news={n} />;
          })}
        </Grid3>
      </PageWrapper>
    </>
  );
}

export default News;
