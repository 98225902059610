import { useNavigate } from "react-router-dom";
// import axios from "axios";
import Button from "../../../components/common/button/button";
import { Wrapper } from "./news-card.e";
import Icon from "../../../components/common/Icon/Icon";
import Trash from "../../../assets/images/trash.svg"
import { deleteNews } from "../../../services";
function NewsCard({ news }) {
  const navigate = useNavigate();

  // delete news
  const onDeleteNews = () => {
  
      deleteNews( { _id: news._id })
      .then((response) => {
        navigate(0);
      })
      .catch((error) => {
      });
  };


  return (
    <Wrapper onClick={() => navigate(`/create-news/${news._id}`)}>
      <img className="news-img" src={news.image} alt="" />
      <h2>{news.description_title}</h2>
      <p>{news.start_date}</p>
      
      <div>

      <Button
        onClick={onDeleteNews}
        // color="danger"
        size="sm"
        text={<Icon file={Trash}   title="trash"/>}

        // text={<i className="fa-solid fa-trash" />}
      />
      </div>
    </Wrapper>
  );
}

export default NewsCard;
