import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  padding-bottom: 20px;
  padding: 16px;
`;

export const CategoryWithSubs = styled.div`
  .right {
    margin: 20px auto;
    display: flex;
    justify-content: right;
    gap: 20px;
  }
`;

export const TopCategory = styled.div`
  padding: 10px 18px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 15px;
  color: var(--text);
  font-weight: 600;
  background-color: rgb(63, 80, 137, 0.18);
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  i {
    margin-right: 15px;
  }
  .btn {
    /* padding: 14px 20px; */
    text-align: left;
    text-transform: uppercase;
    /* width: 100%; */
    border: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    color: var(--text);
    cursor: pointer;
  }
`;

export const SubCategory = styled(TopCategory)`
  text-transform: capitalize;
  background-color: rgb(63, 80, 137, 0.08);
  font-weight: 500;
  button {
    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 16px;
  div {
    width: 38%;
    display: flex;
    justify-content: space-between;
  }
`;

export const Img = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0;
`;
