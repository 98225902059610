import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Badge from "../../components/common/badge/badge";
import Pagename from "../../components/common/pagename/pagename";
import Loader from "../../components/common/loader/loader";
// import useAxiosFetch from "../../hooks/useAxiosFetch";
import { getOrderById, getClientById } from "../../services/orders";
import {
  Card,
  CardBody,
  CardHead,
  Flex,
  Grid1,
  PageWrapper,
} from "../../components/common/global/global";
import {
  Wrapper,
  ProductImg,
  OrderPrice,
  Timeline,
  TimelineIcon,
  TimelineInfo,
  TimelineHead,
  TimelineDetail,
  Left,
  Right,
  Customer,
} from "./order-detail.e";
import DestinationMap from "./map/destination-map";
dayjs.extend(localizedFormat);

function OrderDetail() {
  const [user, setUser] = useState();
  const [driver, setDriver] = useState();
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData]=useState({})
  const { order_id } = useParams();
  const navigate = useNavigate();

  // const { data } = useAxiosFetch(
  //   "https://beeto.in1.uz/api/moderator-order/get-by-id",
  //   { _id: order_id }
  // );

  useEffect(()=>{
    getOrderById({ _id: order_id })
    .then((response) => {
      const data = response.data;

      // Set the order data in the component state
      setOrderData(data);
      setLoading(false);

      // Perform additional checks or actions based on the fetched data
      if (data.someCondition) {
        // Do something if the condition is met
      } else {
        // Do something else
      }
    })
    .catch((error) => {
      console.error("Error fetching order by ID:", error);

      // Handle the error as needed (e.g., show an error message to the user)
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
 



  useEffect(() => {
    orderData &&
      // axios
      //   .post(
      //     "https://beeto.in1.uz/api/client/get-by-id",
      //     { _id: orderData.client_id },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${
      //           JSON.parse(localStorage.getItem("userData")).token
      //         }`,
      //         "content-type": "application/json",
      //       },
      //     }
      //   )
      getClientById( { _id: orderData.client_id })
        .then((res) => {
          setUser(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          // if (error.response.status === 401) {
          //   localStorage.setItem("userData", "");
          //   navigate(0);
          // }
        });
  }, [orderData]);


  useEffect(() => {
    setLoading(true);
    orderData?.driver_id &&
      axios
        .post(
          "https://beeto.in1.uz/api/driver/get-by-id",
          { _id: orderData.driver_id },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("userData")).token
              }`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setDriver(res.data.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.setItem("userData", "");
            navigate(0);
          }
        });
  }, [orderData]);

  const createdTime =
  orderData && dayjs(orderData.created_time).format("MMM D, YYYY HH:mm");
  const activeTime =
  orderData && dayjs(orderData.active_time).format("ddd, MMM D, YYYY HH:mm");
  const acceptTime =
  orderData && dayjs(orderData.accept_time).format("MMM D, YYYY HH:mm");
  const cancelledTime =
  orderData && dayjs(orderData.cancelled_time).format("MMM D, YYYY HH:mm");
  const completedTime =
  orderData && dayjs(orderData.completed_time).format("MMM D, YYYY HH:mm");
  // const pickupTime =
  //   data && dayjs(data.pickup_time).format("MMM D, YYYY HH:mm");

  return (
    <>
      <Pagename text={"Order Details"} />
      <PageWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Wrapper>
            <Left>
              <Grid1>
                <Card>
                  <CardHead>
                    <h2>
                      <span>Order #{orderData._id.slice(0, 6)}</span>
                    </h2>
                    <Flex>
                      <Badge
                        uppercase
                        text={orderData.state}
                        color={
                          orderData.state === "in-progress"
                            ? "secondary"
                            : orderData.state === "cancelled"
                            ? "danger"
                            : orderData.state === "completed"
                            ? "success"
                            : orderData.state === "active"
                            ? "info"
                            : "primary"
                        }
                      />
                      {orderData.state !== "cancelled" &&
                        orderData.state !== "completed" &&
                        orderData.payment_type !== "payme" && (
                          <Badge
                            onClick={() => navigate(`/order-edit/${orderData._id}`)}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            uppercase
                            text="Edit"
                            color="primary"
                          />
                        )}
                    </Flex>
                  </CardHead>
                  <CardBody className="p0">
                    <div style={{ overflowX: "auto" }}>
                      <table>
                        <thead>
                          <tr>
                            <th width="40%">Product Details</th>
                            <th width="15%">Item Price</th>
                            <th width="15%">Quantity</th>
                            <th width="15%">Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderData.items.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="title">
                                  <ProductImg>
                                    <LazyLoadImage
                                      alt=""
                                      effect="blur"
                                      src={item.representation}
                                    />
                                  </ProductImg>
                                  <div>
                                    <p>{item.name}</p>
                                  </div>
                                </td>
                                <td>{item.price.toLocaleString()} sum</td>
                                <td>{item.count}</td>
                                <td>
                                  {(item.price * item.count).toLocaleString()}{" "}
                                  sum
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <OrderPrice>
                      <div>
                        <p>Sub total :</p>
                        <p>{orderData.sub_total?.toLocaleString()} sum</p>
                      </div>
                      <div>
                        <p>Delivery :</p>
                        <p>{orderData.delivery?.toLocaleString()} sum</p>
                      </div>
                      {orderData.promocode_price > 0 && (
                        <div>
                          <p>Promocode :</p>
                          <p>-{orderData?.promocode_price?.toLocaleString()} sum</p>
                        </div>
                      )}
                      <div className="total">
                        <p>Total (UZS) :</p>
                        <p>{orderData.total.toLocaleString()} sum</p>
                      </div>
                    </OrderPrice>
                  </CardBody>
                </Card>
                <Card>
                  <CardHead>
                    <h2>Order Status</h2>
                    {orderData?.update_moderator_name && (
                      <p>
                        The order was updated by{" "}
                        <b>{orderData?.update_moderator_name}</b> on{" "}
                        <b>
                          {dayjs(orderData?.update_time).format("MMM D, YYYY HH:mm")}
                        </b>
                      </p>
                    )}
                  </CardHead>
                  <CardBody>
                    <Timeline className="has-next">
                      <TimelineIcon>
                        <i className="fa-regular fa-bag-shopping" />
                      </TimelineIcon>
                      <TimelineInfo>
                        <TimelineHead>Order Placed</TimelineHead>
                        <TimelineDetail>
                          <h6>
                            An order has been placed{" "}
                            <span>
                              by {user?.first_name} {user?.last_name}
                            </span>
                            .
                          </h6>
                          <p>{createdTime}</p>
                        </TimelineDetail>
                      </TimelineInfo>
                    </Timeline>
                    {!orderData.cancelled_time && (
                      <>
                        <Timeline
                          className={!orderData.active_time ? "not-active" : ""}
                        >
                          <TimelineIcon
                            className={!orderData.active_time ? "not-active" : ""}
                          >
                            <i className="fa-regular fa-bag-shopping" />
                          </TimelineIcon>
                          <TimelineInfo>
                            <TimelineHead>Order Actived</TimelineHead>
                            {orderData.active_time && (
                              <TimelineDetail>
                                <h6>
                                  An order has been actived{" "}
                                  <span>
                                    by{" "}
                                    {orderData.moderator_name
                                      ? orderData.moderator_name
                                      : "Seller"}
                                  </span>
                                </h6>
                                <p>{activeTime}</p>
                              </TimelineDetail>
                            )}
                          </TimelineInfo>
                        </Timeline>
                        <Timeline
                          className={!orderData.accept_time ? "not-active" : ""}
                        >
                          <TimelineIcon
                            className={!orderData.accept_time ? "not-active" : ""}
                          >
                            <i className="fa-regular fa-gift" />
                          </TimelineIcon>
                          <TimelineInfo>
                            <TimelineHead>Order Accepted</TimelineHead>
                            {orderData.accept_time && (
                              <TimelineDetail>
                                <h6>
                                  An order has been accepted{" "}
                                  <span>
                                    by{" "}
                                    {orderData.moderator_name
                                      ? orderData.moderator_name
                                      : "Seller"}
                                  </span>
                                </h6>
                                <p>{acceptTime}</p>
                              </TimelineDetail>
                            )}
                          </TimelineInfo>
                        </Timeline>
                        {/* <Timeline
                          className={!data.pickup_time ? "not-active" : ""}
                        >
                          <TimelineIcon
                            className={!data.pickup_time ? "not-active" : ""}
                          >
                            <i className="fa-regular fa-truck-moving" />
                          </TimelineIcon>
                          <TimelineInfo>
                            <TimelineHead>Pickup Time</TimelineHead>
                            {data.pickup_time && (
                              <TimelineDetail>
                                <h6>Seller has proccessed the order.</h6>
                                <p>{pickupTime}</p>
                              </TimelineDetail>
                            )}
                          </TimelineInfo>
                        </Timeline> */}
                        <Timeline
                          className={
                            !orderData.completed_time
                              ? "not-active no-line"
                              : "no-line"
                          }
                        >
                          <TimelineIcon
                            className={!orderData.completed_time ? "not-active" : ""}
                          >
                            <i className="fa-regular fa-box-check" />
                          </TimelineIcon>
                          <TimelineInfo>
                            <TimelineHead>Order Completed</TimelineHead>
                            {orderData.completed_time && (
                              <TimelineDetail>
                                <h6>
                                  Order has been completed{" "}
                                  <span>
                                    by{" "}
                                    {orderData.moderator_name
                                      ? orderData.moderator_name
                                      : "Seller"}
                                  </span>
                                </h6>
                                <p>{completedTime}</p>
                              </TimelineDetail>
                            )}
                          </TimelineInfo>
                        </Timeline>
                      </>
                    )}
                    {orderData.cancelled_time && (
                      <Timeline className="no-line">
                        <TimelineIcon>
                          <i className="fa-regular fa-circle-x" />
                        </TimelineIcon>
                        <TimelineInfo>
                          <TimelineHead>
                            Order Cancelled{" "}
                            <span>
                              by{" "}
                              {orderData.moderator_name
                                ? orderData.moderator_name
                                : "Seller"}
                            </span>
                          </TimelineHead>
                          <TimelineDetail>
                            <h6>Reason: {orderData.cancel_reason}</h6>
                            <p>{cancelledTime}</p>
                          </TimelineDetail>
                        </TimelineInfo>
                      </Timeline>
                    )}
                  </CardBody>
                </Card>
              </Grid1>
            </Left>
            <Right>
              <Grid1>
                {driver && (
                  <Card>
                    <CardHead>
                      <h5>
                        <span>Driver</span>
                      </h5>
                    </CardHead>
                    <CardBody>
                      <Customer>
                        <img
                          src={
                            "https://cdn.dribbble.com/users/1248571/screenshots/6701015/_____4x.png?compress=1&resize=1200x900&vertical=top"
                          }
                          alt=""
                        />
                        <p>
                          <b>
                            {driver?.first_name} {driver?.last_name}
                          </b>
                          <span>{driver?.phone_number}</span>
                        </p>
                      </Customer>
                    </CardBody>
                  </Card>
                )}
                <Card>
                  <CardHead>
                    <h5>
                      <span>Customer Details</span>
                    </h5>
                  </CardHead>
                  {user && (
                    <CardBody>
                      <Customer>
                        <img
                          alt=""
                          src={
                            user.image && user.image.search("http") !== -1
                              ? user.image
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfoOWTFCoMlIoe0_BUCDhsa94mUcuu65mIcA&usqp=CAU"
                          }
                        />
                        <p>
                          <b>
                            {user?.first_name} {user?.last_name}
                          </b>
                          <span>{user?.phone_number}</span>
                        </p>
                      </Customer>
                    </CardBody>
                  )}
                </Card>
                <Card>
                  <CardHead>
                    <h5>
                      <span>Delivery Address</span>
                    </h5>
                    <DestinationMap
                      market={[
                        orderData.from_location.latitude,
                        orderData.from_location.longitude,
                      ]}
                      destination={[
                        orderData.location.latitude,
                        orderData.location.longitude,
                      ]}
                    />
                  </CardHead>
                  <CardBody>
                    <ul>
                      <li>{orderData.location.location_name}</li>
                    </ul>
                  </CardBody>
                </Card>
                <Card>
                  <CardHead>
                    <h5>
                      <span>Order Comment</span>
                    </h5>
                  </CardHead>
                  <CardBody>
                    <ul>
                      <li>
                        {orderData?.description
                          ? orderData?.description
                          : "No comments by user"}
                      </li>
                    </ul>
                  </CardBody>
                </Card>
                {orderData?.receipt?.amount && (
                  <Card>
                    <CardHead>
                      <h5>
                        <span>Payment</span>
                      </h5>
                    </CardHead>
                    <CardBody>
                      <ul>
                        <li className="flex">
                          <span>Transactions</span>
                          <p>#{orderData?.receipt?._id.slice(0, 18)}</p>
                        </li>
                        <li className="flex">
                          <span>Payment time</span>
                          <p>
                            {dayjs(orderData?.receipt?.create_time).format(
                              "MMM D, YYYY HH:mm"
                            )}
                          </p>
                        </li>
                        <li className="flex">
                          <span>Payment method: </span>
                          <p>Debit Card</p>
                        </li>
                        <li className="flex">
                          <span>Card number: </span>
                          <p>
                            {orderData?.receipt?.card_number?.slice(0, 4)} xxxx xxxx{" "}
                            {orderData?.receipt?.card_number?.slice(
                              orderData?.receipt?.card_number?.length - 4,
                              orderData?.receipt?.card_number?.length
                            )}
                          </p>
                        </li>
                        <li className="flex">
                          <span>Total Amount: </span>
                          <p>{orderData?.receipt?.amount.toLocaleString()} sum</p>
                        </li>
                        <li className="flex">
                          <span>Status: </span>
                          <Badge
                            text={
                              orderData.is_paid
                                ? "Paid"
                                : orderData.is_paid === false
                                ? "Not Paid"
                                : "Lack of funds"
                            }
                            color={
                              orderData.is_paid
                                ? "success"
                                : orderData.is_paid === false
                                ? "danger"
                                : "warning"
                            }
                          />
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                )}
              </Grid1>
            </Right>
          </Wrapper>
        )}
      </PageWrapper>
    </>
  );
}

export default OrderDetail;
