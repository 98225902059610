import React from "react";
import { Editor } from "@tinymce/tinymce-react";

class HTMLEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: this.props.content };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(content, editor) {
    this.props.onChange(content);
  }
  handleSubmit(event) {
    alert("Text was submitted: " + this.state.content);
    event.preventDefault();
  }

  render() {
    return (
      <div style={{ margin: "20px 0" }}>
        <Editor
          value={this.props.content}
          init={{
            height: 300,
            menubar: false,
          }}
          onEditorChange={this.handleChange}
        />
      </div>
    );
  }
}

export default HTMLEditor;
