import { useEffect, useState } from "react";
import Loader from "../../components/common/loader/loader";
import Pagename from "../../components/common/pagename/pagename";
import { Grid4, PageWrapper } from "../../components/common/global/global";
import { Wrapper } from "./ads.e";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import Card from "./ads-card";
import AdCreate from "./add-create.js";
import { getAds } from "../../services/settings.js";

function Ads() {
  const [inputCategories, setInputCategories] = useState([]);
  const [ads, setAds]=useState()
  const [loaded, setLoaded]=useState(false)
  // const { data: ads, loaded } = useAxiosFetch(
  //   "https://beeto.in1.uz/api/ad/get",
  //   {}
  // );
    useEffect(()=>{
      setLoaded(true);
      getAds({}).then((res)=>{
        setAds(res.data)
      }).catch((err)=>alert(err)).finally(()=>{
        setLoaded(false)
      })

    },[])

  const { data: categories } = useAxiosFetch(
    "https://beeto.in1.uz/api/category/top/get",
    {}
  );

  useEffect(() => {
    const formatted_category = categories?.map((category) => {
      return {
        value: category._id,
        label: category.name,
      };
    });
    setInputCategories(formatted_category);
  }, [categories]);

  return (
    <>
      <Pagename text={"Advertisements"} />
      <PageWrapper>
        {!loaded ? (
          <Loader />
        ) : (
          <Wrapper>
            <AdCreate />
            <Grid4>
              {ads?.map((ad, i) => (
                <Card inputCategories={inputCategories} ad={ad} key={i} />
              ))}
            </Grid4>
          </Wrapper>
        )}
      </PageWrapper>
    </>
  );
}

export default Ads;
