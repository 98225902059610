import { useState, useEffect } from "react";
import BannerCard from "./banner-card/banner-card";
import Loader from "../../components/common/loader/loader";
import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper, Grid4 } from "../../components/common/global/global";
// import useAxiosFetch from "../../hooks/useAxiosFetch";
import BannerCreate from "./banner-create";
import { getBanners } from "../../services";
function Banners() {
  const [banners, setBanners]=useState()
  const [loaded, setLoaded]=useState(false)

    useEffect(()=>{
      setLoaded(true)
      getBanners().then((res)=>{
        setBanners(res.data)
      }).catch((err)=>err).finally(()=>{
        setLoaded(false)
      })
    },[])

  return (
    <>
      <Pagename text={"Banners"} />
      {loaded && <Loader />}
      <PageWrapper style={loaded ? { opacity: 0 } : { opacity: 1 }}>
        <BannerCreate />
        <Grid4>
          {banners?.map((banner, i) => {
            return <BannerCard key={i} banner={banner} />;
          })}
        </Grid4>
      </PageWrapper>
    </>
  );
}

export default Banners;
