import { useState, useEffect } from "react";
import Loader from "../../components/common/loader/loader";
import Pagename from "../../components/common/pagename/pagename";
import { Flex, PageWrapper } from "../../components/common/global/global";
import Navigation from "../../components/common/navigation/navigation";
import Row from "./row";
import { Wrapper } from "./drivers.e";
// import useAxiosFetch from "../../hooks/useAxiosFetch";
import CreateDriver from "./create-driver/create-driver";
import useRegions from "../../hooks/useRegions";
import Search from "../../components/common/search/search";
import { getDrivers } from "../../services";

function Drivers() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [drivers, setDrivers] = useState({ data: [], total: 0 });
  const [loaded, setLoaded] = useState(false);

  const fetchData = async () => {
    try {
      const result = await getDrivers({ limit: 10, page: page, search: search });
      setDrivers(result);
      setLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, search]);
  console.log("drivers", drivers)

  const { regionsDropdown } = useRegions();

  return (
    <>
      <Pagename text={"Drivers"} />
      <PageWrapper>
        {!loaded ? (
          <Loader />
        ) : (
          <Wrapper>
            <Flex
              style={{ padding: "20px 30px", justifyContent: "space-between" }}
            >
              <Search
                value={search}
                onChange={setSearch}
                small={true}
                placeholder="Search ..."
              />
              <CreateDriver />
            </Flex>

            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th width="14%">First Name</th>
                    <th width="14%">Last Name</th>
                    <th width="14%">Phone Number</th>
                    <th width="10%">Status</th>
                    <th width="10%">Total Orders</th>
                    <th width="15%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {drivers.data?.data?.map((driver, i) => {
                    return (
                      <Row
                        regionsDropdown={regionsDropdown}
                        key={i}
                        driver={driver}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Navigation
              current={page}
              total={Math.round(drivers?.total / 10)}
              next={() => setPage(page + 1)}
              prev={() => setPage(page - 1)}
              onChange={setPage}
            />
          </Wrapper>
        )}
      </PageWrapper>
    </>
  );
}

export default Drivers;
