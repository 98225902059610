import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  animation: 0.4s anime ease-in-out;
  @keyframes anime {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
