import { request } from "../api/axios";

export const getSettings = () => {
    return request({ method: "get", url: "/setting" });
  };
  export const updateSetting = (data) => {
    return request({ method: "post", url: "/set/setting", data });
  };
  export const updateBanner= (data) => {
    return request({ method: "post", url: "/banner/update", data });
  };
  export const deleteBanner= (data) => {
    return request({ method: "post", url: "/banner/delete", data });
  };
  export const deleteNews= (data) => {
    return request({ method: "post", url: "/news/delete", data });
  };
  export const getBanners= (data) => {
    return request({ method: "post", url: "/moderator/banner/get", data });
  };
  export const getNews = (data) => {
    return request({ method: "post", url: "/news/get-pagin" , data});
  };
  export const updateNews= (data) => {
    return request({ method: "post", url: "/news/update", data });
  };
  export const creatingNews= (data) => {
    return request({ method: "post", url: "/news/create", data });
  };
  export const getRegions= () => {
    return request({ method: "get", url: "/regions/moderator-get" });
  };
  export const updateRegions= (data) => {
    return request({ method: "post", url: "/region/update", data });
  };
  export const createRegions= (data) => {
    return request({ method: "post", url: "/region/create", data });
  };
  export const createPromo= (data) => {
    return request({ method: "post", url: "/promocode/create", data });
  };
  export const getPromocodes= (data) => {
    return request({ method: "post", url: "/promocode/get-pagin" , data});
  };
  export const updatePromo= (data) => {
    return request({ method: "post", url: "/promocode/update", data });
  };
  export const deletePromo= (data) => {
    return request({ method: "post", url: "/promocode/delete", data });
  };

  export const getAds= (data) => {
    return request({ method: "post", url: "/ad/get" , data});
  };
  export const updateAds= (data) => {
    return request({ method: "post", url: "ad/update", data });
  };
  export const deleteAds= (data) => {
    return request({ method: "post", url: "/ad/delete", data });
  };
  export const createAds= (data) => {
    return request({ method: "post", url: "ad/create", data });
  };