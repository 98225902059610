import styled from "styled-components";

export const Wrapper = styled.div`
  .styledTaskName {
    padding: 10px 18px;
    margin: 10px 0;
    border-radius: 4px;
    font-size: 15px;
    color: var(--text);
    font-weight: 600;
    background-color: rgb(63, 80, 137, 0.18);
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  }
`;
