import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditablePolygon from "../editable-polygon/editable-polygon";
import Button from "../../../components/common/button/button";
import Modal from "../../../components/common/modal/modal";
import { Wrapper } from "./create-region.e";
import { Buttons } from "../../../components/categories/categories.e";
import { EditItem, Label } from "../../item-edit/item-edit.e";
import { createRegions } from "../../../services";

function CreateRegion() {
  const [modal, setModal] = useState(false);
  const [coords, setCoords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fields.setFieldValue("coordinates", {
      type: "Polygon",
      coordinates: [coords],
    });
    let temporal = [];
    coords.length > 1 &&
      coords.map((i) => {
        return temporal.push({
          latitude: i[0],
          longitude: i[1],
        });
      });
    fields.setFieldValue("paths", temporal);
  }, [coords]);

  const fields = useFormik({
    initialValues: {
      paths: [],
      name: "",
    },
  });

  const createRegion = () => {
      createRegions(  { ...fields.values })
      .then((res) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //   localStorage.setItem("userData", "");
          //   navigate(0);
        }
      });
  };

  return (
    <Wrapper>
      <Button
        onClick={() => setModal(true)}
        outline
        color="primary"
        text="Add Region"
      />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "340px" : "750px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <EditItem>
            <Label>Name</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="Enter region name"
              {...fields.getFieldProps("name")}
            />
          </EditItem>
          <EditablePolygon coords={coords} onChange={setCoords} />
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Cancel"
              color="light"
            />
            <Button onClick={createRegion} text="Create" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Wrapper>
  );
}

export default CreateRegion;
