import { request } from "../api/axios";

export const getCategories = (data) => {
    return request({ method: "post", url: "/news-category/moderator/get", data });
  };

  export const getAllCategories = (data) => {
    return request({ method: "post", url: "/category/moderator/get", data });
  };
  export const onUpdatePosition = (data) => {
    return request({ method: "post", url: "/category/draggable/update", data });
  };
  export const updateCategories = (data) => {
    return request({ method: "post", url: "/category/moderator/update", data });
  };
  export const updateSubCategories = (data) => {
    return request({ method: "post", url: "/category/draggable/update", data });
  };
  export const deleteCategory = (data) => {
    return request({ method: "post", url: "/category/moderator/delete", data });
  };