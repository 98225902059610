import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useAxiosFetch = (url, body, isSelect, firstSelect, count) => {
  const [data, setData] = useState(null);
  const [select, setSelect] = useState([firstSelect && firstSelect]);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoaded(false);
    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData")).token
          }`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      })
      .finally(() => setLoaded(true));
  }, [
    body?.page,
    body?.state,
    body?.from_date,
    body?.to_date,
    body?.type,
    body?.search,
    count,
  ]);

  useEffect(() => {
    isSelect &&
      data?.map((item) => {
        setSelect((previous) => [
          ...previous,
          { label: item.name, value: item._id },
        ]);
      });
  }, [loaded]);

  return { data, error, loaded, select };
};

export default useAxiosFetch;
