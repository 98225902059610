import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { YMaps, Placemark, Map, ZoomControl } from "react-yandex-maps";
import CustomDropdown from "../../components/common/dropdown/dropdown";
import { Buttons } from "../../components/categories/categories.e";
import Modal from "../../components/common/modal/modal";
import Button from "../../components/common/button/button";
import Badge from "../../components/common/badge/badge";
import UploadImg from "../../components/common/upload-img/upload-img";
import MultiSelect from "../../components/common/multi-select/multi-select";
import { Img } from "../clients/clients.e";
import { EditItem, Label } from "../item-edit/item-edit.e";
import { updateDrivers } from "../../services";

function Row({ driver, regionsDropdown }) {
  const {
    _id,
    first_name,
    last_name,
    phone_number,
    status,
    total_orders,
    regions,
    image,
  } = driver;
  const [modal, setModal] = useState(false);
  const [map, setMap] = useState(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    let temporal = [];
    regions.map((region) => {
      temporal.push({ label: region.name, value: region._id });
    });
    setSelected(temporal);
  }, [regions]);

  useEffect(() => {
    const formatted = [];
    selected.map((i) => {
      return formatted.push(i.value);
    });
    fields.setFieldValue("regions", formatted);
  }, [selected]);

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const fields = useFormik({
    initialValues: {
      _id: _id,
      first_name: first_name,
      last_name: last_name,
      phone_number: phone_number,
      status: status,
      regions: regions,
      image: image,
    },
  });

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    axios
      .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  const updateClient = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/driver/update",
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      updateDrivers({ ...fields.values })
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        console.log("err", error)
      });
  };

  return (
    <tr>
      <td style={{ paddingLeft: "20px" }}>
        {first_name ? first_name : <span>To'ldirilmagan</span>}
      </td>
      <td>{last_name ? last_name : <span>To'ldirilmagan</span>}</td>
      <td>{phone_number}</td>
      <td>
        <Badge
          text={status === "active" ? "Active" : "Blocked"}
          soft={status === "active" ? "success" : "danger"}
          uppercase
        />
      </td>
      <td>{total_orders} ta</td>
      <td>
        <Button onClick={openModal} text="Edit" soft="primary" size="sm" />
        {driver.driver_location?.latitude && status !== "blocked" && (
          <Button
            onClick={() => setMap(true)}
            size="sm"
            text="See"
            soft="secondary"
            uppercase
          />
        )}
        {modal && (
          <Modal
            width={window.innerWidth < 800 ? "350px" : "550px"}
            setOpen={closeModal}
            open={modal}
          >
            <Img>
              <UploadImg
                width="150px"
                height="150px"
                url={fields.values.image}
                onChange={(e) => {
                  uploadAvatar(e);
                }}
              />
            </Img>
            <EditItem>
              <Label>First Name</Label>
              <input
                className="form-control"
                type="text"
                autoComplete="off"
                placeholder="To'ldirilmagan"
                {...fields.getFieldProps("first_name")}
              />
            </EditItem>
            <EditItem>
              <Label>Last Name</Label>
              <input
                className="form-control"
                placeholder="To'ldirilmagan"
                autoComplete="off"
                type="text"
                {...fields.getFieldProps("last_name")}
              />
            </EditItem>
            <EditItem>
              <Label>Phone Number</Label>
              <input
                className="form-control"
                type="text"
                autoComplete="off"
                {...fields.getFieldProps("phone_number")}
              />
            </EditItem>
            <EditItem>
              <Label>Regions</Label>
              <MultiSelect
                options={regionsDropdown && regionsDropdown}
                selected={selected && selected}
                setSelected={setSelected}
              />
            </EditItem>
            <EditItem>
              <Label>Status</Label>
              <CustomDropdown
                options={[
                  { label: "active", value: "active" },
                  { label: "block", value: "block" },
                ]}
                defaultOption={{
                  label: fields.values.status,
                  value: fields.values.status,
                }}
                onChange={(e) => fields.setFieldValue("status", e.value)}
              />
            </EditItem>
            <Buttons>
              <Button onClick={closeModal} text="Cancel" color="light" />
              <Button onClick={updateClient} text="Save" color="primary" />
            </Buttons>
          </Modal>
        )}
        {map && (
          <Modal
            width={window.innerWidth < 800 ? "350px" : "650px"}
            setOpen={() => setMap(false)}
            open={map}
          >
            {driver.driver_location && (
              <YMaps query={{ apikey: "a611d201-19b9-4184-98c3-e7d6c4de6c1d" }}>
                <div style={{ overflow: "hidden" }}>
                  <Map
                    width="100%"
                    height="420px"
                    defaultState={{
                      center: [
                        driver.driver_location?.latitude,
                        driver.driver_location?.longitude,
                      ],
                      zoom: 16,
                    }}
                  >
                    <Placemark
                      geometry={[
                        driver.driver_location.latitude,
                        driver.driver_location.longitude,
                      ]}
                    />
                    <ZoomControl options={{ float: "right" }} />
                  </Map>
                </div>
              </YMaps>
            )}
          </Modal>
        )}
      </td>
    </tr>
  );
}

export default Row;
