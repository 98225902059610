import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  background-color: var(--pagename);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  table {
    .address-th {
      width: 300px !important;
    }
    .address {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      p {
        margin-right: 0;
      }
    }
    .payment {
      margin-right: 4px;
    }
    .status {
      width: 82px !important;
    }
    td {
      padding: 15px 0;
      i {
        margin-left: 18px;
        font-size: 15px;
        cursor: pointer;
        color: var(--indigo);
        &.red {
          color: var(--red);
        }
      }
    }
    th,
    td {
      width: auto !important;
    }
  }
  span {
    color: #878a99 !important;
    opacity: 0.8;
  }
  animation: 0.4s anime ease-in-out;
  .search {
    padding-top: 20px;
    margin: 20px;
  }
  @keyframes anime {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  padding: 15px 0;
  padding-right: 20px;
  overflow-x: auto;
  align-items: center;
  .small {
    width: auto !important;
    margin-left: 20px;
  }
  input {
    margin-right: 20px;
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  padding: 9px 16px;
  font-weight: 600;
  margin: 0 4px;
  color: var(--indigo);
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid transparent;
  span {
    padding-right: 8px;
    transition: 0.15s all ease-in-out;
  }
  i {
    margin-right: 6px;
    font-weight: 700;
  }
  &.active {
    border-bottom: 2px solid var(--green);
    span,
    i {
      color: var(--green) !important;
    }
  }
  &:hover {
    span,
    i {
      color: var(--indigo) !important;
    }
  }
  div {
    margin-left: 6px;
    background-color: var(--red);
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 9px;
  }
  @media (max-width: 800px) {
    white-space: nowrap;
  }
`;
