import { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/button/button";
import Modal from "../../components/common/modal/modal";
import UploadImg from "../../components/common/upload-img/upload-img";
import { Img } from "../clients/clients.e";
import { Right } from "./banner-card/banner-card.e";
import { Buttons } from "../../components/categories/categories.e";

function BannerCreate() {
  const [modal, setModal] = useState();
  const navigate = useNavigate();
  const fields = useFormik({
    initialValues: {
      image: "",
    },
  });

  const createBanner = () => {
    axios
      .post(
        "https://beeto.in1.uz/api/banner/create",
        { ...fields.values },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      });
  };

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    axios
      .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <Right>
      <Button
        onClick={() => setModal(true)}
        text="Create banner"
        color="primary"
      />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "320px" : "550px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <UploadImg
            width="100%"
            height="300px"
            url={fields.values.image}
            onChange={(e) => {
              uploadAvatar(e);
            }}
          />
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Cancel"
              color="light"
            />
            <Button onClick={createBanner} text="Create" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Right>
  );
}

export default BannerCreate;
