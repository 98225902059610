import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
`;

export const CreateNewsCard = styled.div`
  padding: 50px 40px;
  width: 45%;
  background-color: #fff;
  border-radius: 10px;
`;
