import styled from "styled-components";

export const Wrapper = styled.div`
  cursor: pointer;
  img {
    width: 100%;
    height: 150px;
    border-radius: 24px;
  }
  @media (max-width: 800px) {
    img {
      height: auto;
    }
  }
`;

export const Right = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: right;
`;
