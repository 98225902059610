import { request } from "../api/axios";

export const getOrders = (data) => {
  return request({ method: "post", url: "/moderator-order/get-pagin", data });
};

export const getOrderById = (data) => {
  return request({ method: "post", url: "/moderator-order/get-by-id", data });
};

export const getClientById = (data) => {
  return request({ method: "post", url: "/client/get-by-id", data });
};
export const activateOrder = (data) => {
  return request({ method: "post", url: "/order-moderator/active", data });
};
export const orderProgress = (data) => {
  return request({ method: "post", url: "/order-moderator/accept", data });
};

export const orderComplete = (data) => {
  return request({ method: "post", url: "/moderator-order/completed", data });
};
export const rejectOrder = (data) => {
  return request({ method: "post", url: "/moderator-order/reject", data });
};
