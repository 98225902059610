import Button from "../../components/common/button/button";
import { Right } from "./moderator.e";
import { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Buttons } from "../../components/categories/categories.e";
import { EditItem, Label } from "../item-edit/item-edit.e";
import Modal from "../../components/common/modal/modal";
import { ModeratorCard } from "./moderator.e";
import { Flex } from "../../components/common/global/global";
import { createModerator } from "../../services";
import Badge from "../../components/common/badge/badge";

function ModeratorCreate() {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const fields = useFormik({
    initialValues: {
      username: "",
      password: "",
      access_rights: {
        reports: false,
        orders: false,
        drivers: false,
        clients: false,
        banners: false,
        regions: false,
        news: false,
        settings: false,
        organizations: false,
        moderators: false,
        products: false,
      },
    },
  });

  // Create moderator
  const createModerator = () => {
    // axios
    //   .post(
    //     `https://beeto.in1.uz/api/moderator/create`,
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      createModerator({ ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((err) => {
        
          alert(err.response.data.data);
        
      });
  };

  return (
    <Right>
      <Button
        onClick={() => setModal(true)}
        text="Create moderator"
        color="primary"
      />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "350px" : "550px"}
          setOpen={setModal}
          open={modal}
        >
          <EditItem>
            <Label>Username</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="To'ldirilmagan"
              {...fields.getFieldProps("username")}
            />
          </EditItem>
          <EditItem>
            <Label>Password</Label>
            <input
              className="form-control"
              placeholder="Enter new password"
              autoComplete="off"
              type="text"
              {...fields.getFieldProps("password")}
            />
          </EditItem>
          <EditItem
            style={{ display: "flex", flexWrap: "wrap", gap: "8px 20px" }}
          >
            <Flex>
              <input
                type="checkbox"
                name="reports"
                id="reports"
                checked={fields.values.access_rights.reports}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    reports: !fields.values.access_rights.reports,
                  })
                }
              />
              <Label htmlFor="reports">Reports</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="orders"
                id="orders"
                checked={fields.values.access_rights.orders}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    orders: !fields.values.access_rights.orders,
                  })
                }
              />
              <Label for="orders">Orders</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="drivers"
                id="drivers"
                checked={fields.values.access_rights.drivers}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    drivers: !fields.values.access_rights.drivers,
                  })
                }
              />
              <Label for="drivers">Orders</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="clients"
                id="clients"
                checked={fields.values.access_rights.clients}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    clients: !fields.values.access_rights.clients,
                  })
                }
              />
              <Label for="clients">Clients</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="banners"
                id="banners"
                checked={fields.values.access_rights.banners}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    banners: !fields.values.access_rights.banners,
                  })
                }
              />
              <Label for="banners">Banners</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="regions"
                id="regions"
                checked={fields.values.access_rights.regions}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    regions: !fields.values.access_rights.regions,
                  })
                }
              />
              <Label for="regions">Regions</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="news"
                id="news"
                checked={fields.values.access_rights.news}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    news: !fields.values.access_rights.news,
                  })
                }
              />
              <Label for="news">News</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="settings"
                id="settings"
                checked={fields.values.access_rights.settings}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    settings: !fields.values.access_rights.settings,
                  })
                }
              />
              <Label for="settings">Settings</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="organizations"
                id="organizations"
                checked={fields.values.access_rights.organizations}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    organizations: !fields.values.access_rights.organizations,
                  })
                }
              />
              <Label for="organizations">Organizations</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="products"
                id="products"
                checked={fields.values.access_rights.products}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    products: !fields.values.access_rights.products,
                  })
                }
              />
              <Label for="products">Products</Label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="moderators"
                id="moderators"
                checked={fields.values.access_rights.moderators}
                onChange={() =>
                  fields.setFieldValue("access_rights", {
                    ...fields.values.access_rights,
                    moderators: !fields.values.access_rights.moderators,
                  })
                }
              />
              <Label for="moderators">Moderators</Label>
            </Flex>
          </EditItem>
          <Buttons>
            <Button onClick={createModerator} text="Save" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Right>
  );
}

export default ModeratorCreate;
