import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/common/modal/modal";
import Button from "../../components/common/button/button";
import { Buttons } from "../../components/categories/categories.e";
import Timer from "./timer/timer";
import Badge from "../../components/common/badge/badge";
import { EditItem, Label } from "../item-edit/item-edit.e";
import TrashIcon from "../../assets/images/icon/trash.gif";
import BadgeCheck from "../../assets/images/badge-check.svg";
import CircleCheck from "../../assets/images/check-circle.svg";
import EyeIcon from "../../assets/images/eye.svg";
import Trash from "../../assets/images/trash.svg";
import Truck from "../../assets/images/truck.svg";
import FileIcon from "../../assets/images/alternate-file.svg";
import FingerprintIcon from "../../assets/images/icon/fingerprint.gif";
import Confetti from "../../assets/images/icon/confetti.gif";
import GiftIcon from "../../assets/images/icon/gift.gif";
import DriversSelect from "../../components/common/drivers-select/drivers-select";
import Icon from "../../components/common/Icon/Icon";
import {
  activateOrder,
  orderProgress,
  orderComplete,
  rejectOrder,
} from "../../services/orders";

dayjs.extend(localizedFormat);

function Row({ item, stateNow }) {
  const {
    total,
    address,
    client_name,
    client_phone,
    created_time,
    state,
    cancelled_time,
    completed_time,
    accept_time,
    order_number,
    _id,
  } = item;

  const [reasonModal, setReasonModal] = useState(false);
  const [driver, setDriver] = useState();
  const [reason, setReason] = useState("");
  const [deleteError, setDeleteError] = useState(false);
  const [newOrderModal, setNewOrderModal] = useState(false);
  const [activeOrderModal, setActiveOrderModal] = useState(false);
  const [inProgressOrder, setInProgressOrder] = useState(false);
  const navigate = useNavigate();
  // const createdTime = dayjs(created_time).format("DD MMM YYYY, HH:mm");
  const acceptTime = dayjs(accept_time).format("DD MMM YYYY, HH:mm");
  const cancelledTime = dayjs(cancelled_time).format("DD MMM YYYY, HH:mm");
  const completedTime = dayjs(completed_time).format("DD MMM YYYY, HH:mm");
  const totalPrice = total.toLocaleString();

  // toast message
  const successMessage = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  // toast message
  const deleteMessage = () =>
    toast.error("Order Deleted!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const acceptOrder = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/order-moderator/active",
    //     { order_id: _id },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    activateOrder({ order_id: _id })
      .then((res) => {
        successMessage("Order Actived!");
        setNewOrderModal(false);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };

  const progressOrder = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/order-moderator/accept",
    //     { order_id: _id, driver_id: driver },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    orderProgress({ order_id: _id, driver_id: driver })
      .then((res) => {
        successMessage("Order in process");
        setActiveOrderModal(false);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };

  const completeOrder = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/moderator-order/completed",
    //     { order_id: _id },

    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    orderComplete({ order_id: _id })
      .then((res) => {
        successMessage("Order completed");
        setInProgressOrder(false);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };

  const orderReject = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/moderator-order/reject",
    //     { order_id: _id, reason: reason },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
    rejectOrder({ order_id: _id, reason: reason })
      .then((res) => {
        deleteMessage();
        setReasonModal(false);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   localStorage.setItem("userData", "");
        //   navigate(0);
        // }
      });
  };

  const getDocument = (order_id, file_order) => {
    axios
      .get(
        `https://beeto.in1.uz/api/order/pdf/${order_id}/order${file_order}.pdf `,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        var link = document.createElement("a");
        link.href = url;
        link.download = "file.pdf";
        link.dispatchEvent(new MouseEvent("click"));
      });
  };

  return (
    <tr>
      {order_number && (
        <td
          id="orderNumber"
          style={{
            paddingLeft: "20px",
            textAlign: "left",
          }}
        >
          <p>{order_number}.</p>
        </td>
      )}
      {address && (
        <td title={address}>
          <p
            style={
              stateNow === "active" || stateNow === "new-order"
                ? { width: "272px" }
                : { width: "272px" }
            }
            className="address"
          >
            {address}
          </p>
        </td>
      )}
      {total && (
        <td>
          <p>{totalPrice} sum</p>
        </td>
      )}
      <td>
        <p>{client_name}</p>
      </td>
      {client_phone && (
        <td>
          <p>{client_phone}</p>
        </td>
      )}

      {created_time && (
        <td className="payment">
          <Badge
            soft={
              item.payment_type !== "cash" && item?.is_paid === false
                ? "danger"
                : !item?.is_paid && item.payment_type !== "cash"
                ? "warning"
                : "success"
            }
            text={
              item.payment_type.charAt(0).toUpperCase() +
              item.payment_type.slice(1)
            }
          />
        </td>
      )}
      {accept_time && stateNow === "in-progress" && <td>{acceptTime} </td>}
      {cancelled_time && <td>{cancelledTime}</td>}
      {completed_time && stateNow === "completed" && <td>{completedTime}</td>}
      {created_time && (
        <td>
          <Timer date={created_time} state={state} />
        </td>
      )}
      <td
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          cursor: "pointer",
        }}
      >
        {/* <i
          onClick={() => getDocument(item._id, item.order_number)}
          className="fa-regular fa-file-pdf"
        /> */}
        <Icon
          file={FileIcon}
          onClick={() => getDocument(item._id, item.order_number)}
          title="file"
        />
        <Icon
          file={EyeIcon}
          onClick={() => navigate(`/order/${_id}`)}
          title="eye"
        />
        {/* <img src={FileIcon}  width={19} height={19} onClick={() => getDocument(item._id, item.order_number)}  alt="file"/> */}
        {/* <img src={EyeIcon}  width={19} height={19}  onClick={() => navigate(`/order/${_id}`)} alt="eye"/> */}
        {/* <i
          onClick={() => navigate(`/order/${_id}`)}
          className="fa-solid fa-eye"
        /> */}
        {stateNow === "new-order" && (
          <Icon
            file={CircleCheck}
            onClick={() => setNewOrderModal(true)}
            title="circle"
          />

          // <img src={CircleCheck}   width={19} height={19}   onClick={() => navigate(`/order/${_id}`)} alt="circle"/>

          // <i
          //   onClick={() => setNewOrderModal(true)}
          //   className="fa-regular fa-box-circle-check"
          // />
        )}
        {stateNow === "active" && (
          <Icon
            file={Truck}
            onClick={() => setActiveOrderModal(true)}
            title="trash"
          />

          // <img src={Trash}  width={19} height={19}  onClick={() => setActiveOrderModal(true)} alt="trash"  />

          // <i
          //   onClick={() => setActiveOrderModal(true)}
          //   className="fa-regular fa-truck-moving"
          // />
        )}
        {stateNow === "in-progress" && (
          <Icon
            file={BadgeCheck}
            onClick={() => setInProgressOrder(true)}
            title="badge"
          />
        )}
        {stateNow !== "cancelled" && stateNow !== "completed" && (
          <Icon
            file={Trash}
            onClick={() => setReasonModal(true)}
            title="trash"
          />

          // <i
          //   onClick={() => setReasonModal(true)}
          //   className="fa-solid fa-trash-can red"
          // />
        )}

        {reasonModal && (
          <Modal
            width={window.innerWidth < 800 ? "300px" : "460px"}
            setOpen={() => setReasonModal(false)}
            open={reasonModal}
          >
            <img className="gif" src={TrashIcon} alt="" />
            <h5>Are you sure ?</h5>
            <p className="text">
              Do you want to delete <br /> this order ?
            </p>
            <input
              placeholder="Cancel reason"
              type="text"
              className="form-control"
              onChange={(e) => setReason(e.target.value)}
            />
            {deleteError && (
              <p className="error">
                Field should contain at least 10 characters
              </p>
            )}
            <Buttons>
              <Button
                onClick={() => setReasonModal(false)}
                text="Close"
                color="light"
              />
              <Button
                onClick={
                  reason.length > 10 ? orderReject : () => setDeleteError(true)
                }
                text="Yes, delete it!"
                color="danger"
              />
            </Buttons>
          </Modal>
        )}
        {newOrderModal && (
          <Modal
            width={window.innerWidth < 800 ? "300px" : "400px"}
            setOpen={() => setNewOrderModal(false)}
            open={newOrderModal}
          >
            <img className="gif" src={FingerprintIcon} alt="" />
            <h5>Are you sure ?</h5>
            <p className="text">
              Do you want to accept <br /> this order ?
            </p>
            <Buttons>
              <Button
                onClick={() => setNewOrderModal(false)}
                text="Close"
                color="light"
              />
              <Button
                onClick={acceptOrder}
                text="Accept order"
                color="primary"
              />
            </Buttons>
          </Modal>
        )}
        {activeOrderModal && (
          <Modal
            width={window.innerWidth < 800 ? "300px" : "400px"}
            setOpen={() => setActiveOrderModal(false)}
            open={activeOrderModal}
          >
            <EditItem>
              <img className="gif" src={GiftIcon} alt="" />
              <h5>Are you sure ?</h5>
              <p className="text">
                Do you want to make <br /> in progress this order ?
              </p>
              <Label>Select a driver</Label>
              <DriversSelect onChange={setDriver} />
            </EditItem>
            <Buttons>
              <Button
                onClick={() => setActiveOrderModal(false)}
                text="Close"
                color="light"
              />
              <Button
                onClick={progressOrder}
                text="Make in progress"
                color="primary"
              />
            </Buttons>
          </Modal>
        )}
        {inProgressOrder && (
          <Modal
            width={window.innerWidth < 800 ? "300px" : "400px"}
            setOpen={() => setInProgressOrder(false)}
            open={inProgressOrder}
          >
            <img className="gif" src={Confetti} alt="" />
            <h5>Are you sure ?</h5>
            <p className="text">
              Do you want to complete <br /> this order ?
            </p>
            <Buttons>
              <Button
                onClick={() => setInProgressOrder(false)}
                text="Close"
                color="light"
              />
              <Button
                onClick={completeOrder}
                text="Complete order"
                color="primary"
              />
            </Buttons>
          </Modal>
        )}
      </td>
      <ToastContainer />
    </tr>
  );
}

export default Row;
