import { useEffect, useState } from "react";
import { getAllCategories } from "../services";

export default function useCategories() {
  const [categories, setCategories] = useState();
  const [types, setTypes] = useState([{ label: "top", value: "top" }]);

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res.data);
      
      // Construct the new types array and update it once
      const newTypes = res.data.map((category) => ({
        label: category.name,
        value: category._id,
      }));

      setTypes((previous) => [...previous, ...newTypes]);
    });
  }, []);

  return { categories, types };
}
