import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  background-color: var(--card-bg);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  margin-bottom: 1rem;
  border-radius: 6px;
  position: relative;
  margin-top: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  color: var(--text-body);
  transition: 0.3s all ease-in-out;
  a {
    color: var(--indigo);
    text-decoration: underline;
  }
  &:hover {
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h4`
  font-weight: 500;
  color: #878a99;
  text-transform: uppercase;
  margin: 0;
`;

export const Percent = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;
  i {
    margin-right: 8px;
    font-size: 12px;
    transform: translateY(0.5px);
  }
  &.green {
    color: var(--green);
  }
  &.red {
    color: var(--red);
  }
  &.yellow {
    color: var(--yellow);
  }
  &.dark {
    color: var(--text-body);
  }
`;

export const CountValue = styled.h4`
  margin: 1.5rem 0;
  font-weight: 600;
  font-size: 22px;
`;

export const PaymentCount = styled.h4`
  font-weight: 600;
  font-size: 18px;
`;

export const Icon = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
