import { request } from "../api/axios";

export const getModerators = () => {
    return request({ method: "post", url: "/moderators/get" });
  };
  export const updateModerator = (data) => {
    return request({ method: "post", url: "/moderator/update", data });
  };
  export const createModerator = (data) => {
    return request({ method: "post", url: "/moderator/create", data });
  };
