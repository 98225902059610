import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagename from "../../components/common/pagename/pagename";
import { FlexCommon, PageWrapper } from "../../components/common/global/global";
import Navigation from "../../components/common/navigation/navigation";
import ProductTable from "../../components/products/items/product-table/product-table";
import Search from "../../components/common/search/search";
import Loader from "../../components/common/loader/loader";
import Button from "../../components/common/button/button";
import { Wrapper } from "./new-product.e";
import { getProducts, syncItem } from "../../services";

function NewProduct() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sLoading, setSLoading] = useState(false);
  const [loaded, setLoaded]=useState(false)
  const [products, setProducts]=useState()
  const navigate = useNavigate();


  useEffect(()=>{
    setLoaded(true)
    getProducts( {
      limit: 20,
      page: page,
      search: `${search}`,
    }).then((res)=>{
      setProducts(res.data)
    }).catch((err)=>err).finally(()=>{
      setLoaded(false)
    })
  },[page, search])


  const syncItems = () => {
    setSLoading(true);
  
    syncItem({})
      .then((response) => {
        setSLoading(false);
      })
      .catch((error) => {
        
          setSLoading(false);
          alert(error.response.data.error);
        
      });
  };

  return (
    <>
      <Pagename text={"New Product"} />
      <PageWrapper>
        {loaded ? (
          <Loader />
        ) : (
          <Wrapper>
            <FlexCommon className="flex">
              <Button
                onClick={syncItems}
                text="Synchronize Items"
                color="primary"
                loading={sLoading}
              />
              <Search
                value={search}
                onChange={setSearch}
                small={true}
                placeholder="Search ..."
              />
            </FlexCommon>
            <div className="responsive-table">
              {products?.data && <ProductTable data={products.data} />}
            </div>
          </Wrapper>
        )}
        {products?.total > 20 && (
          <Navigation
            current={page}
            total={Math.round(products.total / 20)}
            next={() => setPage(page + 1)}
            prev={() => setPage(page - 1)}
            onChange={setPage}
          />
        )}
      </PageWrapper>
    </>
  );
}

export default NewProduct;
