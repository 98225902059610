import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagename from "../../components/common/pagename/pagename";
import { Grid2, PageWrapper } from "../../components/common/global/global";
import Button from "../../components/common/button/button";
import Loader from "../../components/common/loader/loader";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import {
  BasketItem,
  CardBody,
  CardFooter,
  Cards,
  CountButtons,
  Products,
  Wrapper,
} from "./order-edit.e";
import AddProduct from "./add-product";

function OrderEdit() {
  const [order, setOrder] = useState();
  const { order_id } = useParams();
  const navigate = useNavigate();
  let { data, loaded } = useAxiosFetch(
    "https://beeto.in1.uz/api/moderator-order/get-by-id",
    { _id: order_id }
  );
  const [cards, setCards] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [beforeUsed, setBeforeUsed] = useState();

  useEffect(() => {
    data &&
      setOrder({
        _id: data._id,
        items: data.items,
      });
    data && setSelectedCard(data.card_id);
    data && setBeforeUsed(data.card_id);
  }, [data]);

  useEffect(() => {
    if (data && data?.payment_type !== "cash" && !data?.is_paid) {
      axios
        .post(
          "https://beeto.in1.uz/api/card-moderator/get-pagin",
          { limit: 20, page: 1, client_id: data.client_id },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("userData")).token
              }`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setCards(res.data.data);
        });
    }
  }, [data]);

  const updateOrder = () => {
    axios
      .post(
        "https://beeto.in1.uz/api/order-moderator/update",
        { _id: order._id, items: order.items, card_id: selectedCard },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        navigate(-1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            navigate(-1);
          }, 5000);
        }
      });
  };

  return (
    <>
      <Pagename text={"Order Edit"} />
      <PageWrapper>
        {!loaded ? (
          <Loader />
        ) : (
          <Wrapper>
            <AddProduct order={order} setOrder={setOrder} />
            <Products>
              {order &&
                order.items.map((item, i) => {
                  return (
                    <BasketItem key={item.item_id}>
                      <CardBody>
                        <div className="img">
                          <img src={item.representation} alt={item.name} />
                        </div>
                        <div className="info">
                          <p>{item.name}</p>
                          <span>
                            {item.size ? (
                              <>
                                {item.size} {item.size_type}
                              </>
                            ) : (
                              "Not filled"
                            )}
                          </span>
                          <CountButtons>
                            <button
                              onClick={() => {
                                let oldStates = { ...order };
                                if (
                                  oldStates.items.length === 1 &&
                                  oldStates.items[0].count === 1
                                ) {
                                  return alert(
                                    "You can't remove this item, because order will be empty"
                                  );
                                } else {
                                  if (oldStates.items[i].count === 1) {
                                    oldStates.items = [
                                      ...oldStates.items.slice(0, i),
                                      ...oldStates.items.slice(
                                        i + 1,
                                        oldStates.items.length
                                      ),
                                    ];
                                    return setOrder(oldStates);
                                  }
                                  oldStates.items[i].count =
                                    oldStates.items[i].count - 1;
                                  setOrder(oldStates);
                                }
                              }}
                            >
                              <i className="fa-solid fa-minus" />
                            </button>
                            <span>{item.count}</span>
                            <button
                              onClick={() => {
                                let oldStates = { ...order };
                                oldStates.items[i].count =
                                  oldStates.items[i].count + 1;
                                setOrder(oldStates);
                              }}
                            >
                              <i className="fa-solid fa-plus" />
                            </button>
                          </CountButtons>
                        </div>
                        <div className="price">
                          <span>Item Price:</span>
                          <p>{item.price?.toLocaleString()} sum</p>
                        </div>
                      </CardBody>
                      <CardFooter>
                        <button
                          onClick={() => {
                            let oldStates = { ...order };
                            if (oldStates.items.length === 1) {
                              alert(
                                "You can't remove this item, because order will be empty"
                              );
                            } else {
                              oldStates.items = [
                                ...oldStates.items.slice(0, i),
                                ...oldStates.items.slice(
                                  i + 1,
                                  oldStates.items.length
                                ),
                              ];
                              setOrder(oldStates);
                            }
                          }}
                        >
                          <i className="fa-regular fa-trash-can" />
                          Remove
                        </button>
                        <p>
                          <span>Total: </span>
                          <b>
                            {(item.price * item.count).toLocaleString()} sum
                          </b>
                        </p>
                      </CardFooter>
                    </BasketItem>
                  );
                })}
            </Products>
            {cards && cards.total > 0 && (
              <Grid2>
                <Cards>
                  <p>Cards</p>
                  {cards &&
                    cards?.data?.map((card, i) => {
                      return (
                        <label
                          key={i}
                          htmlFor={`input-${i}`}
                          className={beforeUsed === card._id ? "used" : ""}
                        >
                          <input
                            onChange={() => setSelectedCard(card._id)}
                            type="radio"
                            name={`input`}
                            id={`input-${i}`}
                            value={card.number}
                            checked={selectedCard === card._id}
                          />
                          {card.number}
                        </label>
                      );
                    })}
                </Cards>
              </Grid2>
            )}

            <div className="right">
              <Button
                onClick={updateOrder}
                text="Update order"
                color="secondary"
              />
            </div>
          </Wrapper>
        )}
      </PageWrapper>
      <ToastContainer />
    </>
  );
}

export default OrderEdit;
