import { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import Badge from "../../../components/common/badge/badge";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

function Timer({ date, state }) {
  const [time, setTime] = useState("dd, hh:mm:ss");
  const [lockdown, setLockdown] = useState(dayjs(date));

  useEffect(() => {
    setLockdown(dayjs(date));
  }, [date]);

  function getDifference() {
    const currentDate = dayjs();
    const diff = currentDate.diff(lockdown);
    const diffDuration = dayjs.duration(diff);
    setTime({
      days: diffDuration.days(),
      hours: diffDuration.hours(),
      minutes: diffDuration.minutes(),
      seconds: diffDuration.seconds(),
    });
  }

  useEffect(() => {
    getDifference();
    setInterval(() => getDifference(), 1000);
  }, [date]);

  return (
    time && (
      <Badge
        uppercase={state === "completed" || state === "cancelled"}
        text={
          state !== "cancelled" && state !== "completed" ? (
            <>
              {time.days > 0 && time.days + "d, "}{" "}
              {time.hours < 10 ? `0${time.hours}` : time.hours}:
              {time.minutes < 10 ? `0${time.minutes}` : time.minutes}:
              {time.seconds < 10 ? `0${time.seconds}` : time.seconds}
            </>
          ) : (
            state
          )
        }
        soft={
          time.minutes < 30 && time.hours <= 0 && time.days <= 0
            ? "success"
            : time.hours < 3 && !time.days && state !== "cancelled"
            ? "warning"
            : state === "completed"
            ? "success"
            : "danger"
        }
      />
    )
  );
}

export default Timer;
