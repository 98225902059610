import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useCategories from "../../hooks/useCategory";
import MultiSelect from "../../components/common/multi-select/multi-select";
import Button from "../../components/common/button/button";
import Select from "../../components/common/select/select";
import UploadImg from "../../components/common/upload-img/upload-img";
import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper } from "../../components/common/global/global";
import { getSingleProduct, uploadImg, onUpdateItem} from "../../services";
import useTags from "../../hooks/useTags";
import {
  EditItem,
  EditSection,
  Grid2x2,
  Label,
  TextRight,
  Wrapper,
} from "./item-edit.e";

function ItemEdit() {
  const [selected, setSelected] = useState([]);
  const [isNew, setIsNew] = useState([]);
  const { product_id } = useParams();
  const { categories } = useCategories();
  const { tagDropdown } = useTags();
  const navigate = useNavigate();
  let categories_dropdown = [];
  useEffect(() => {
    function getCategories() {
      categories &&
        categories.map((category) => {
          let childs = [];
          category.children.map((child) => {
            childs.push({
              value: child._id,
              label: child.name,
            });
          });
          categories_dropdown.push({
            type: "group",
            name: category.name,
            items: [...childs],
          });
        });
    }
    getCategories();
  }, [categories, categories_dropdown]);

  useEffect(() => {
    const formatted = [];
    selected.map((i) => {
      return formatted.push({ tag_id: i.value });
    });
    fields.setFieldValue("tags", formatted);

  }, [selected]);

  useEffect(() => {
    async function getData() {
   
      getSingleProduct({ _id: product_id })
        .then((response) => {
          console.log("response")
          fields.setFieldValue("name", response.data.name);
          fields.setFieldValue("compound", response.data.compound);
          fields.setFieldValue("brand", response.data.brand);
          fields.setFieldValue("manufacturer", response.data.manufacturer);
          fields.setFieldValue(
            "representation",
            response.data.data.representation
          );
          fields.setFieldValue("size", response.data.size);
          fields.setFieldValue("size_type", response.data.size_type);
          fields.setFieldValue("name_lang.en", response.data.name_lang.en);
          fields.setFieldValue("name_lang.ru", response.data.name_lang.ru);
          fields.setFieldValue("is_new", response.data.is_new);
          fields.setFieldValue(
            "category_name",
            response.data.data.category_name
          );
          fields.setFieldValue("category_id", response.data.category_id);
          fields.setFieldValue(
            "active_for_sale",
            response.data.active_for_sale
          );
          let tags = [];
          response.data?.tags.forEach((a) => {
            tags.push({ label: a.tag_name, value: a.tag_id });
          });
          setIsNew(response.data.is_new);

          setSelected(tags);
        })
        .catch((error) => {});
    }
    getData();
  }, [product_id]);

  // Fields control
  const fields = useFormik({
    initialValues: {
      _id: product_id,
      name: "",
      representation: "",
      compound: "...",
      brand: "...",
      manufacturer: "...",
      size: "",
      size_type: "",
      name_lang: {
        en: "",
        ru: "",
      },
      category_name: "",
      category_id: "",
      is_new: "",
      active_for_sale: "",
    },
  });

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("representation", res.data.data.path);
      });
  };

  // Update item
  const updateItem = () => {
      onUpdateItem( { ...fields.values })
      .then((response) => {
        navigate("/products/all");
      })
      .catch((err) => {
        
          alert(err.response.data.data);
        
      });
  };

  return (
    <>
      <Pagename text={"Edit"} />
      <PageWrapper>
        <Wrapper>
          <div>
            <EditSection>
              <EditItem>
                <Label>Title</Label>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  autoComplete="off"
                  {...fields.getFieldProps("name")}
                />
              </EditItem>
              <EditItem>
                <Label htmlFor="title-en">Product Title (EN)</Label>
                <input
                  id="title-en"
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter product title"
                  {...fields.getFieldProps("name_lang.en")}
                />
              </EditItem>
              <EditItem>
                <Label htmlFor="title-ru">Product Title (RU)</Label>
                <input
                  id="title-ru"
                  className="form-control"
                  type="text"
                  placeholder="Enter product title"
                  {...fields.getFieldProps("name_lang.ru")}
                />
              </EditItem>
              <EditItem>
                <Label htmlFor="description">Product Description</Label>
                <textarea
                  id="description"
                  className="form-control"
                  placeholder="Enter product description"
                  {...fields.getFieldProps("compound")}
                />
              </EditItem>
              <Grid2x2>
                <EditItem>
                  <Label htmlFor="brand">Manufacturer Brand</Label>
                  <input
                    id="brand"
                    className="form-control"
                    type="text"
                    placeholder="Enter product brand"
                    {...fields.getFieldProps("brand")}
                  />
                </EditItem>
                <EditItem>
                  <Label htmlFor="manufacturer">Manufacturer Name</Label>
                  <input
                    id="manufacturer"
                    className="form-control"
                    type="text"
                    placeholder="Enter product manufacturer"
                    {...fields.getFieldProps("manufacturer")}
                  />
                </EditItem>
              </Grid2x2>
              <Grid2x2>
                <EditItem>
                  <Label htmlFor="size">Size</Label>
                  <input
                    id="size"
                    className="form-control"
                    type="number"
                    placeholder="Enter size"
                    {...fields.getFieldProps("size")}
                  />
                </EditItem>
                <EditItem>
                  <Label htmlFor="size_type">Size type</Label>
                  <Select
                    id="size_type"
                    onChange={(e) => fields.setFieldValue("size_type", e.value)}
                    defaultOption={fields.values.size_type}
                    options={["ml", "l", "kg", "g", "mg", "dona"]}
                  />
                </EditItem>
              </Grid2x2>
              <Grid2x2>
                <EditItem>
                  <Label>Category</Label>
                  <Select
                    defaultOption={fields.values.category_name}
                    options={categories_dropdown}
                    onChange={(e) => {
                      fields.setFieldValue("category_name", e.label);
                      fields.setFieldValue("category_id", e.value);
                    }}
                  />
                </EditItem>
                <EditItem>
                  <Label>Tags</Label>
                  <MultiSelect
                    options={tagDropdown && tagDropdown}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </EditItem>
              </Grid2x2>
              <Grid2x2>
                {isNew && (
                  <EditItem>
                    <Label>New Product</Label>
                    <input
                      type="checkbox"
                      name="isNew"
                      id="isNew"
                      checked={fields.values.is_new}
                      onChange={() =>
                        fields.setFieldValue("is_new", !fields.values.is_new)
                      }
                    />
                    <label htmlFor="isNew">Is new?</label>
                  </EditItem>
                )}
                <EditItem>
                  <Label>Active for sale</Label>
                  <input
                    type="checkbox"
                    name="active_for_sale"
                    id="active_for_sale"
                    checked={fields.values.active_for_sale}
                    onChange={() =>
                      fields.setFieldValue(
                        "active_for_sale",
                        !fields.values.active_for_sale
                      )
                    }
                  />
                  <label htmlFor="active_for_sale">Active</label>
                </EditItem>
              </Grid2x2>
            </EditSection>
          </div>
          <div>
            <EditSection>
              <EditItem>
                <Label>Product Image</Label>
                <UploadImg
                  width="100%"
                  height="300px"
                  onChange={(e) => {
                    uploadAvatar(e);
                  }}
                  url={fields.values.representation}
                />
              </EditItem>
            </EditSection>
          </div>
          <TextRight>
            <Button onClick={updateItem} text="Submit" color="success" />
          </TextRight>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default ItemEdit;
