import Pagename from "../../components/common/pagename/pagename";
import { Grid4, PageWrapper } from "../../components/common/global/global";
import { Wrapper } from "./organization.e";
import ImageCrop from "../../components/common/upload-img/image-crop/image-crop";

function Organizations() {
  return (
    <>
      <Pagename text={"Organizations"} />
      <PageWrapper>
        <Wrapper>
          <Grid4>
            <ImageCrop />
          </Grid4>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default Organizations;
