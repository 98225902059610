import { useState, useEffect } from "react";
import Pagename from "../../components/common/pagename/pagename";
import { PageWrapper } from "../../components/common/global/global";
import CategoriesContent from "../../components/categories/categories";
import { getCategories } from "../../services";
// import useAxiosFetch from "../../hooks/useAxiosFetch";
import { Wrapper } from "./new-category.e";

function NewCategory() {

  const [categories, setCategories]=useState()
  const [loaded, setLoaded]=useState(false)

  // const { data: categories, loaded } = useAxiosFetch(
  //   "https://beeto.in1.uz/api/news-category/moderator/get"
  // );

  useEffect(()=>{
    setLoaded(true)
    getCategories().then((res)=>{
      console.log("res", res)
      setCategories(res.data.data)
    }).catch((err)=>alert(err)).finally(()=>{
      setLoaded(false)
    })
  },[])
  return (
    <>
      <Pagename text={"New Category"} />
      <PageWrapper>
        <Wrapper>
          <CategoriesContent categories={categories} loaded={loaded} />
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default NewCategory;
