import { request } from "../api/axios";

export const getProducts = (data) => {
    return request({ method: "post", url: "/new-item/moderator/get-pagin", data });
  };
  export const getAllItems = (data) => {
    return request({ method: "post", url: "/item/moderator/get-pagin", data });
  };
  export const getAllProducts = (data) => {
    return request({ method: "post", url: "/item/moderator/get-pagin", data });
  };
  export const syncItem=(data)=>{
    return request({method:"post", url:"/item/moderator/sync", data})
  }
  export const getSingleProduct=(data)=>{
    return request({method:"post", url:"/item/moderator/get/by-id", data})
  }
  export const onUpdateItem=(data)=>{
    return request({method:"post", url:"/item/moderator/update", data})
  }