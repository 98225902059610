import { useRef, useState } from "react";
import Modal from "../../../components/common/modal/modal";
import { YMaps, Map, ZoomControl } from "react-yandex-maps";

export default function DestinationMap({ market, destination }) {
  const map = useRef(null);
  const [modal, setModal] = useState(false);
  const mapState = {
    center: [41.3281887, 69.3187637],
    zoom: 13,
  };

  const addRoute = (ymaps) => {
    const multiRoute = new ymaps.multiRouter.MultiRoute(
      {
        referencePoints: market && destination && [market, destination],
        params: {
          routingMode: "pedestrian",
        },
      },
      {
        boundsAutoApply: true,
      }
    );

    map.current.geoObjects.add(multiRoute);
  };

  return (
    <>
      <i
        onClick={() => setModal(true)}
        className="fa-regular fa-location-dot"
      />
      {modal && (
        <Modal width="850px" setOpen={() => setModal(false)} open={modal}>
          <YMaps query={{ apikey: "a611d201-19b9-4184-98c3-e7d6c4de6c1d" }}>
            <Map
              width="100%"
              height="360px"
              modules={["multiRouter.MultiRoute"]}
              state={mapState}
              instanceRef={map}
              onLoad={addRoute}
            >
              <ZoomControl />
            </Map>
          </YMaps>
        </Modal>
      )}
    </>
  );
}
