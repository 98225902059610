import Pagename from "../../components/common/pagename/pagename";
import { useState, useEffect } from "react";
import { Grid3, PageWrapper } from "../../components/common/global/global";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { Wrapper } from "./promo-code.e";
import CreateCode from "./create-code";
import UpdateCard from "./update-code";
import { getPromocodes } from "../../services";

function PromoCode() {
  const[promoCodes, setPromoCodes]=useState()
 
  useEffect(()=>{
    getPromocodes({
      limit: 10,
      page: 1,
    }).then((res)=>{
      setPromoCodes(res.data)
    })
  },[])
  return (
    <>
      <Pagename text={"Promo Codes"} />
      <PageWrapper>
        <Wrapper>
          <CreateCode />
          <Grid3 style={{ gridGap: "6px 30px" }}>
            {promoCodes?.data?.map((code, i) => {
              return <UpdateCard key={i} promo={code} />;
            })}
          </Grid3>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

export default PromoCode;
