import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import axios from "axios";
import Button from "../../components/common/button/button";
import Modal from "../../components/common/modal/modal";
import UploadImg from "../../components/common/upload-img/upload-img";
import { Buttons } from "../../components/categories/categories.e";
import { EditItem, Label } from "../item-edit/item-edit.e";
import { Right } from "./ads.e";
import { uploadImg, createAds } from "../../services";

function AdCreate() {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const fields = useFormik({
    initialValues: {
      image: "",
      description: "",
      start_date: "",
      end_date: "",
    },
  });

  const createAd = () => {
    // axios
    //   .post(
    //     "https://beeto.in1.uz/api/ad/create",
    //     { ...fields.values },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${
    //           JSON.parse(localStorage.getItem("userData")).token
    //         }`,
    //         "content-type": "application/json",
    //       },
    //     }
    //   )
      createAds( { ...fields.values })
      .then((res) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      });
  };

  // Image upload to server
  const uploadImage = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);
    // axios
    //   .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <Right>
      <Button onClick={() => setModal(true)} color="primary" text="Create Ad" />
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "350px" : "500px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <UploadImg
            width="60%"
            height="150px"
            url={fields.values.image}
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <EditItem>
            <Label>Description</Label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter description of ad"
              autoComplete="off"
              {...fields.getFieldProps("description")}
            />
          </EditItem>
          <EditItem>
            <Label>Start date</Label>
            <input
              type="date"
              placeholder="Start date"
              className="form-control"
              min={dayjs().format("YYYY-MM-DD")}
              autoComplete="off"
              onChange={(e) => {
                var date = new Date(e.target.value);
                var timestamp = date.getTime();
                fields.setFieldValue("start_date", timestamp);
              }}
            />
          </EditItem>
          <EditItem>
            <Label>End date</Label>
            <input
              type="date"
              placeholder="End date"
              className="form-control"
              min={dayjs(fields.values.start_date).format("YYYY-MM-DD")}
              onChange={(e) => {
                var date = new Date(e.target.value);
                var timestamp = date.getTime();
                fields.setFieldValue("end_date", timestamp);
              }}
            />
          </EditItem>
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Cancel"
              color="light"
            />
            <Button onClick={createAd} text="Create" color="primary" />
          </Buttons>
        </Modal>
      )}
    </Right>
  );
}

export default AdCreate;
