import { PageWrapper } from "../../components/common/global/global";
import ReportsSection from "../../components/reports/reports-section";

function Reports() {
  return (
    <>
      <PageWrapper>
        <ReportsSection />
      </PageWrapper>
    </>
  );
}

export default Reports;
