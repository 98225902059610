import axios from "axios";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Pagename from "../../../components/common/pagename/pagename";
import UploadImg from "../../../components/common/upload-img/upload-img";
import Editor from "../../banners/editor/editor";
import { Wrapper, CreateNewsCard } from "./create-news.e";
import { EditItem, Label } from "../../item-edit/item-edit.e";
import { Buttons, Img } from "../../../components/categories/categories.e";
import Button from "../../../components/common/button/button";
import { useNavigate } from "react-router-dom";
import { getNews, uploadImg, updateNews, creatingNews } from "../../../services";

function CreateNews() {
  const { news_id } = useParams();
  const navigate = useNavigate();
  const [news, setNews] = useState();
  const [loaded, setLoaded] = useState();
  useEffect(() => {
    setLoaded(true);
    getNews({ page: 1, limit: 20 })
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoaded(false);
      });
  }, []);
  

  useEffect(() => {
    if (loaded) {
      news.data.filter((news) => {
        if (news._id === news_id) {
          fields.setValues(news);
        }
      });
    }
  }, [news]);

  const fields = useFormik({
    initialValues: {
      image: "",
      description: "",
      description_title: "",
      start_date: "",
      end_date: "",
      status: "active",
    },
  });

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    // axios
    //   .post("https://beeto.in1.uz/api/upload-file", bodyFormData)
      uploadImg(bodyFormData)
      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  const createNews = () => {
    if (news_id) {
        updateNews(  { ...fields.values })
        .then((res) => {
          navigate("/news");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //   localStorage.setItem("userData", "");
            //   navigate(0);
          }
        });
    } else {
        creatingNews( { ...fields.values })
        .then((res) => {
          navigate("/news");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //   localStorage.setItem("userData", "");
            //   navigate(0);
          }
        });
    }
  };

  return (
    <>
      <Pagename text={"Create news"} />
      {!loaded && (
        <Wrapper>
          <CreateNewsCard>
            <Img>
              <UploadImg
                width="50%"
                height="120px"
                url={fields.values.image}
                onChange={(e) => {
                  uploadAvatar(e);
                }}
              />
            </Img>
            <EditItem>
              <Label>Title</Label>
              <input
                id="description_title"
                className="form-control"
                type="text"
                placeholder="Enter news name"
                autoComplete="off"
                {...fields.getFieldProps("description_title")}
              />
            </EditItem>
            <Editor
              onChange={(content) =>
                fields.setFieldValue("description", content)
              }
              content={fields.values.description}
            />
            <EditItem>
              <input
                type="checkbox"
                name="status"
                id="status"
                checked={fields.values.status}
                onChange={() =>
                  fields.setFieldValue("status", !fields.values.status)
                }
              />
              <Label style={{ display: "inline-block", marginLeft: "10px" }}>
                Active
              </Label>
            </EditItem>
            <Buttons style={{ gap: "30px" }}>
              <Button
                onClick={() => navigate("/news")}
                text="Cancel"
                color="light"
              />
              <Button
                onClick={createNews}
                text={news_id ? "Update" : "Create"}
                color="primary"
              />
            </Buttons>
          </CreateNewsCard>
        </Wrapper>
      )}
    </>
  );
}

export default CreateNews;
