import { useEffect, useState } from "react";
import axios from "axios";

export default function useRegions() {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState([]);
  const [regionsDropdown, setRegionsDropdown] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://beeto.in1.uz/api/regions/moderator-get",
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userData")).token
            }`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setRegions(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
      });
    return;
  }, []);

  useEffect(() => {
    regions.map((tag) => {
      setRegionsDropdown((previous) => [
        ...previous,
        { label: tag.name, value: tag._id },
      ]);
    });
  }, [loading]);

  return { regions, regionsDropdown };
}
