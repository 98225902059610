import { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EditItem, Label } from "../../../pages/item-edit/item-edit.e";
import { TopCategory, CategoryWithSubs, Buttons, Img } from "../categories.e";
import { AccordionBody } from "../../products/filter/filter.e";
import Button from "../../common/button/button";
import Subcategory from "./subcategory";
import MultiSelect from "../../common/multi-select/multi-select";
import CustomDropdown from "../../common/dropdown/dropdown";
import Modal from "../../common/modal/modal";
import { List, arrayMove } from "react-movable";
import UploadImg from "../../common/upload-img/upload-img";
import Icon from "../../common/Icon/Icon";
import Trash from "../../../assets/images/trash.svg"
import { updateCategories ,updateSubCategories, deleteCategory, uploadImg } from "../../../services";
function CategoryItem({ category, types, tagDropdown, position }) {
  const [open, setOpen] = useState(false);
  const [subcategory, setSubcategory] = useState(category.children);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [changePosition, setChangePosition] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fields.setFieldValue(
      "tags",
      selected.map((a) => a.value)
    );
  }, [selected]);

  useEffect(() => {
    changePosition > 0 &&
    
        updateSubCategories( {
          categories: subcategory,
        })
        .then((res) => {
          // navigate(0);
        });
  }, [changePosition]);

  // Fields control
  const fields = useFormik({
    initialValues: {
      _id: category._id,
      name: category.name,
      icon: category.icon,
      tags: category.tags && category.tags,
      type: category.type,
      show_on_bot: category.show_on_bot,
      image: category?.image ? category?.image : "",
    },
  });

  // useEffect(() => {
  //   const temporal = [];
  //   category.children.map((item, i) => {
  //     item.draggable_position = i;
  //     temporal.push(item);
  //   });
  //   setSubcategory(temporal);
  // }, []);

  useEffect(() => {
    let tags = [];
    category.tags &&
      category.tags.map((tag) => {
        return tags.push({ value: tag?.tag_id, label: tag?.tag_name });
      });
    setSelected(tags);
  }, [category]);

  useEffect(() => {
    const formatted = [];
    selected.map((i) => {
      return formatted.push({ tag_id: i.value });
    });
    fields.setFieldValue("tags", formatted);
  }, [selected]);

  // Update item
  const updateCategory = () => {
    
      updateCategories({ ...fields.values })
      .then((response) => {
        setModal(false);
        navigate(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("userData", "");
          navigate(0);
        } else {
          alert(error.response.data.data);
        }
      });
  };

  const onDeleteCategory = () => {
  
      deleteCategory({ _id: category._id })
      .then((response) => {
        setModal(false);
        navigate(0);
      });
  };

  // Avatar upload to server
  const uploadAvatar = (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);
  
    uploadImg(bodyFormData)

      .then((res) => {
        fields.setFieldValue("image", res.data.data.path);
      });
  };

  return (
    <CategoryWithSubs>
      <TopCategory onClick={() => setOpen(!open)}>
        <button className="btn" onClick={() => setOpen(!open)}>
          <span>
            <i className="fa-solid fa-chevron-down" />
            {category.name}
          </span>
        </button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setModal(true);
          }}
          text="Edit"
          size="sm"
          color="primary"
        />
      </TopCategory>

      <AccordionBody
        onClick={(e) => e.stopPropagation()}
        style={
          open ? { display: "block", margin: "20px 0" } : { display: "none" }
        }
      >
        {subcategory && position === "Sub categories" && (
          <List
            values={subcategory ? subcategory : ""}
            onChange={({ oldIndex, newIndex }) => {
              setSubcategory(arrayMove(subcategory, oldIndex, newIndex));
              setChangePosition(changePosition + 1);
            }}
            renderList={({ children, props }) => <ul {...props}>{children}</ul>}
            renderItem={({ value, props }) => (
              <div key={value._id} {...props}>
                <Subcategory
                  type={value.name}
                  types={types}
                  tags={tagDropdown}
                  subcategory={value}
                />
              </div>
            )}
          />
        )}
        {!position &&
          subcategory.map((value) => {
            return (
              <Subcategory
                key={value._id}
                type={value.name}
                types={types}
                tags={tagDropdown}
                subcategory={value}
              />
            );
          })}
      </AccordionBody>
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "350px" : "600px"}
          setOpen={() => setModal(false)}
          open={modal}
        >
          <Img>
            <UploadImg
              width="26%"
              height="136px"
              url={fields.values.image}
              onChange={(e) => {
                uploadAvatar(e);
              }}
            />
          </Img>
          <EditItem>
            <Label>Category Name</Label>
            <input
              className="form-control"
              type="text"
              {...fields.getFieldProps("name")}
              disabled={fields.values.name === "Others"}
            />
          </EditItem>
          <EditItem>
            <Label>Category Type</Label>
            <CustomDropdown
              isDisabled={fields.values.name === "Others"}
              options={types}
              defaultOption={{
                label: category.type,
                value: category.type,
              }}
              onChange={(e) => fields.setFieldValue("type", e.value)}
            />
          </EditItem>
          <EditItem>
            <Label>Tags</Label>
            <MultiSelect
              options={tagDropdown && tagDropdown}
              selected={selected}
              setSelected={setSelected}
              isDisabled={fields.values.name === "Others"}
            />
          </EditItem>
          <EditItem>
            <Label>Category Icon</Label>
            <input
              disabled={fields.values.name === "Others"}
              className="form-control"
              type="text"
              {...fields.getFieldProps("icon")}
            />
          </EditItem>
          <EditItem>
            <Label>Active</Label>
            <input
              type="checkbox"
              name="show_on_bot"
              id="show_on_bot"
              checked={fields.values.show_on_bot}
              onChange={() =>
                fields.setFieldValue("show_on_bot", !fields.values.show_on_bot)
              }
            />
          </EditItem>
          <Buttons>
            {fields.values.name !== "Others" && (
              <Button
                onClick={onDeleteCategory}
        
        text={<Icon file={Trash}   title="trash"/>}
                // color="danger"
              />
            )}
            <div>
              <Button
                onClick={() => setModal(false)}
                text="Cancel"
                color="light"
              />
              <Button onClick={updateCategory} text="Save" color="primary" />
            </div>
          </Buttons>
        </Modal>
      )}
    </CategoryWithSubs>
  );
}

export default CategoryItem;
