import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 5px;
  padding-bottom: 20px;
  color: var(--text-body);
  display: grid;
  grid-template-columns: 66% auto;
  grid-gap: 24px;
  .personal-image {
    text-align: center;
  }
  .personal-image input[type="file"] {
    display: none;
  }
  .personal-figure {
    position: relative;
    width: 120px;
    height: 120px;
  }
  .personal-avatar {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 100%;
    border: 2px solid transparent;
    transition: all ease-in-out 0.3s;
  }
  .personal-figcaption {
    cursor: pointer;
    position: absolute;
    top: 0px;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out 0.3s;
  }
  .personal-figcaption:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .personal-figcaption > img {
    margin-top: 32.5px;
    width: 50px;
    height: 50px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const EditSection = styled.div`
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  background-color: var(--pagename);
  padding: 20px;
  border-radius: 6px;
`;

export const EditItem = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 500;
  display: block;
  color: var(--text-body);
  i {
    margin-right: 8px;
    font-size: 18px;
    transform: translateY(1px);
  }
`;

export const Grid2x2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const TextRight = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: right;
  height: 30vh;
  button {
    height: 40px;
    padding: 0 40px;
    font-size: 16px;
  }
`;
