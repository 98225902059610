import { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/common/modal/modal";
import EditablePolygon from "../editable-polygon/editable-polygon";
import Button from "../../../components/common/button/button";
import { Buttons } from "../../../components/categories/categories.e";
import { RegionCard } from "../regions.e";
import { EditItem, Label } from "../../item-edit/item-edit.e";
import CardBg from "../../../assets/images/OS-Street-BW_situ.jpg";
import { updateRegions } from "../../../services";

function RegionContent({ region }) {
  const [modal, setModal] = useState();
  const [coords, setCoords] = useState(region.coordinates.coordinates[0]);
  const navigate = useNavigate();

  useEffect(() => {
    fields.setFieldValue("coordinates", {
      type: "Polygon",
      coordinates: [coords],
    });
    let temporal = [];
    coords.map((i) => {
      return temporal.push({
        latitude: i[0],
        longitude: i[1],
      });
    });
    fields.setFieldValue("paths", temporal);
  }, [coords]);

  const fields = useFormik({
    initialValues: {
      _id: region._id,
      organization: region.organization,
      paths: region.paths,
      name: region.name,
      coordinates: region.coordinates,
      created_at: region.created_at,
      deleted_at: region.deleted_at,
      updated_at: region.updated_at,
      __v: 0,
    },
    enaleReinitialize: true,
  });

  // Update item
  const update = () => {
      updateRegions({ ...fields.values })
      .then((response) => {
        navigate(0);
      })
      .catch((err) => {});
  };

  return (
    <RegionCard>
      <img onClick={() => setModal(true)} src={CardBg} alt="map" />
      <p onClick={() => setModal(true)}>Region {region.name}</p>
      {modal && (
        <Modal
          width={window.innerWidth < 800 ? "340px" : "1300px"}
          open={modal}
          setOpen={setModal}
        >
          <EditItem>
            <Label>Name</Label>
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              placeholder="Enter region name"
              {...fields.getFieldProps("name")}
            />
          </EditItem>
          <EditablePolygon onChange={setCoords} coords={coords} />
          <Buttons>
            <Button
              onClick={() => setModal(false)}
              text="Close"
              color="light"
            />
            <Button onClick={update} text="Update" color="primary" />
          </Buttons>
        </Modal>
      )}
    </RegionCard>
  );
}

export default RegionContent;
